@import url(~slick-carousel/slick/slick.css);
@import url(~slick-carousel/slick/slick-theme.css);
.how-it-works {
  max-width: 1200px;
  padding: 0 20px;
  width: 100%;
  margin: 0 auto; }

.how-it-works h1, .how-it-works .timeline-progress h3 {
  color: #000;
  font-family: "Baufra Semi", sans-serif;
  font-size: 4rem; }

.title-arrow_wrapper img {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%); }

.image-wrapper {
  display: flex;
  justify-content: center;
  align-items: center; }

.how-it-works {
  text-align: center;
  padding-top: 30px;
  margin: 70px auto; }
  .how-it-works .big-arrow {
    margin: 20px 0; }
    @media (max-width: 768px) {
      .how-it-works .big-arrow {
        display: none; } }
  .how-it-works .timeline-progress {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap; }
    @media (max-width: 768px) {
      .how-it-works .timeline-progress {
        padding: 0 20px;
        justify-content: center; }
        .how-it-works .timeline-progress > div {
          margin-bottom: 30px; } }
    @media (max-width: 425px) {
      .how-it-works .timeline-progress {
        display: none; } }
    .how-it-works .timeline-progress h3 {
      font-size: 2.4rem; }
    .how-it-works .timeline-progress > div {
      position: relative;
      max-width: 250px;
      min-height: 350px;
      text-align: center;
      line-height: 1.5;
      font-size: 1.8rem; }
      @media (max-width: 768px) {
        .how-it-works .timeline-progress > div {
          flex: 1; } }
      .how-it-works .timeline-progress > div:last-child .title-arrow_wrapper > img {
        display: none; }

.image-wrapper {
  min-height: 140px; }

.title-arrow_wrapper {
  position: relative; }
  .title-arrow_wrapper img {
    left: 110%;
    transform: translateY(-50%); }
    @media (max-width: 768px) {
      .title-arrow_wrapper img {
        display: none; } }

.slick-slider {
  display: none; }
  @media (max-width: 425px) {
    .slick-slider {
      display: block; } }

.slider_item p {
  max-width: 180px;
  margin: 0 auto; }

.slick-dots li button:before {
  font-size: 12px; }

.slick-dots li {
  margin: 0 -3px; }

.slick-dots li.slick-active button:before,
.slick-dots li button:before {
  color: #42e5aa; }
