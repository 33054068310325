@media (max-width: 425px) {
  .faq-page-wrapper .hero-image {
    min-height: 0 !important;
    max-height: 100px !important; }
    .faq-page-wrapper .active.hero-image {
      min-height: 100% !important;
      padding-top: 50px !important; } }

@media (max-width: 768px) {
  .faq-page-wrapper .hero-image img {
    left: -50%; } }
