.inputs-wrapper button, .first-screen-wrapper .first-screen-block button {
  display: inline-block;
  border-radius: 30px;
  background-color: #fff;
  box-shadow: 0 4px 25px 0 rgba(83, 170, 112, 0.2);
  font-size: 1.5rem;
  font-family: Baufra Semi, sans-serif;
  text-align: center;
  padding: 20px 35px;
  color: #42e5aa;
  cursor: pointer;
  outline: none;
  box-shadow: 0 4px 12px 0 rgba(53, 93, 255, 0.2);
  border: 0; }

.notification-wrapper {
  height: 88px;
  width: 390px;
  border-radius: 10px;
  background-color: #ffffff;
  box-shadow: 0 -2px 4px 0 rgba(0, 0, 0, 0.05), 0 7px 13px 0 rgba(0, 0, 0, 0.08);
  transform: translateX(100%);
  opacity: 0;
  transition: all 0.2s;
  display: flex;
  align-items: center;
  position: fixed;
  top: 15px;
  left: 97.5vw;
  z-index: 13; }
  @media (max-width: 450px) {
    .notification-wrapper {
      transform: translateX(-50%) !important;
      left: 50%;
      width: 320px; } }
  @media (max-width: 320px) {
    .notification-wrapper {
      width: 270px; } }
  .active.notification-wrapper {
    transform: translateX(-100%);
    opacity: 1; }
  .notification-wrapper .color-margin {
    height: 88px;
    min-width: 50px;
    width: 50px;
    border-radius: 10px 0 0 10px; }
  .notice.notification-wrapper .color-margin {
    background: url("../../../images/order/info-white-icon.svg") #42e5aa center center no-repeat; }
  .sticky.notification-wrapper {
    position: fixed; }
  .notification-wrapper .notification-text {
    padding: 0 50px 0 14px;
    font-family: Baufra, sans-serif;
    font-size: 13px; }
  .notification-wrapper .notification-close {
    right: 20px;
    color: #42e5aa;
    background-color: #fff;
    background-size: cover;
    width: 14px;
    height: 14px;
    min-height: 14px;
    padding: 0;
    border: none;
    cursor: pointer; }

.order-page-wrapper .error-block .error-text h1 {
  color: #000;
  font-family: "Baufra Semi", sans-serif;
  font-size: 4rem; }

.order-page-wrapper .error-block .error-text p {
  color: #000;
  font-family: "Baufra Medium", sans-serif;
  font-size: 2rem;
  line-height: 1.4;
  text-align: right; }

.second-option-mobile .select-date-modal-wrapper .select-date-modal-content {
  position: absolute;
  left: 50%;
  transform: translateX(-50%); }

.notification-wrapper .notification-close, .inputs-wrapper button img, .first-screen-wrapper .first-screen-block button img, .dropdown-wrapper > img, .dropdown-wrapper .dropdown-loader {
  position: absolute;
  top: 50%;
  transform: translateY(-50%); }

.second-order-screen .select-step-buttons button.back-order-button img, .mobile-order-steps img:nth-child(2), .mobile-order-steps:before {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%); }

.order-page-wrapper .error-block, .order-page-wrapper .main-order-block, .first-screen-wrapper, .second-order-screen .current-step-wrapper .current-service-item, .second-order-screen .current-step-wrapper .current-service-item .bullet-block, .second-order-screen .select-step-buttons, .first-option-block .order-option, .hours-block .hours-item, .second-option-mobile .select-date-modal-wrapper .select-date-modal-content .select-date-body .modal-date-block .modal-hours-wrapper .hour-block, .fourth-screen-wrapper .congratulation-block, .fourth-screen-wrapper .congratulation-block .fourth-screen-faq-button, .fourth-screen-wrapper .download-app-block {
  display: flex;
  justify-content: center;
  align-items: center; }

@media (max-width: 425px) {
  .order-page-wrapper .hero-image {
    min-height: 0 !important;
    max-height: 100px !important; }
    .order-page-wrapper .active.hero-image {
      min-height: 100% !important;
      padding-top: 50px !important; } }

.inputs-wrapper input, .first-screen-wrapper .first-screen-block input, .contact-section .contact-section-inputs input {
  display: inline-block;
  border-radius: 17px;
  background-color: #fff;
  box-shadow: 0 4px 25px 0 rgba(83, 170, 112, 0.2);
  padding: 18px 35px;
  outline: none;
  border: 1px solid #979797;
  color: #000000; }

.inputs-wrapper button, .first-screen-wrapper .first-screen-block button {
  font-family: Baufra Semi, sans-serif;
  color: #4a90e2;
  padding: 18px 35px;
  max-height: 55px;
  box-shadow: 0 2px 4px 0 rgba(52, 75, 73, 0.5);
  border: 1px solid #e9e9e4;
  min-width: 177px;
  transition: box-shadow 0.1s; }
  .inputs-wrapper button.checked, .first-screen-wrapper .first-screen-block button.checked {
    transition: background-image 0.3s;
    background-image: linear-gradient(-180deg, #12dce7 0%, #72ee6d 100%);
    box-shadow: 0 4px 12px 0 rgba(0, 244, 81, 0.4);
    font-size: 15px;
    color: #ffffff;
    border: rgba(187, 201, 192, 0);
    letter-spacing: 0;
    text-align: center; }
  .inputs-wrapper button:hover, .first-screen-wrapper .first-screen-block button:hover {
    box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.2);
    transition: box-shadow 0.1s; }
  .inputs-wrapper button:active, .first-screen-wrapper .first-screen-block button:active {
    border: #bbc9c0;
    box-shadow: inset 0 1px 12px 0 rgba(0, 0, 0, 0.4); }
  @media (max-width: 768px) {
    .inputs-wrapper button, .first-screen-wrapper .first-screen-block button {
      max-width: 140px;
      margin: 0 auto;
      padding: 15px 30px;
      text-align: center;
      max-width: 160px;
      min-width: 130px; } }
  @media (max-width: 425px) {
    .inputs-wrapper button, .first-screen-wrapper .first-screen-block button {
      max-width: 185px; } }

.inputs-wrapper button, .first-screen-wrapper .first-screen-block button {
  position: relative; }
  .inputs-wrapper button.checked span, .first-screen-wrapper .first-screen-block button.checked span {
    opacity: 1; }
  .inputs-wrapper button img, .first-screen-wrapper .first-screen-block button img {
    position: absolute;
    right: 16px; }
  .inputs-wrapper button span, .first-screen-wrapper .first-screen-block button span {
    opacity: 0.5; }

.first-screen-wrapper .first-screen-block .first-screen-checkboox, .contact-section .contact-section-inputs .checkbox-input {
  margin-top: 32px;
  margin-top: 45px; }
  .first-screen-wrapper .first-screen-block .first-screen-checkboox [type='checkbox'], .contact-section .contact-section-inputs .checkbox-input [type='checkbox'] {
    margin-right: 18px;
    position: absolute;
    opacity: 0; }
  .first-screen-wrapper .first-screen-block .first-screen-checkboox label, .contact-section .contact-section-inputs .checkbox-input label {
    color: #42e5aa;
    font-family: Baufra Semi, sans-serif;
    position: relative;
    cursor: pointer;
    padding: 0; }
    .first-screen-wrapper .first-screen-block .first-screen-checkboox label a, .contact-section .contact-section-inputs .checkbox-input label a {
      font-family: Baufra Semi, sans-serif;
      color: #42e5aa;
      text-decoration: underline; }
  .first-screen-wrapper .first-screen-block .first-screen-checkboox [type='checkbox'] + label, .contact-section .contact-section-inputs .checkbox-input [type='checkbox'] + label {
    position: relative;
    cursor: pointer;
    padding: 0; }
  .first-screen-wrapper .first-screen-block .first-screen-checkboox [type='checkbox'] + label:before, .contact-section .contact-section-inputs .checkbox-input [type='checkbox'] + label:before {
    transition: 0.5s;
    content: '';
    margin-right: 10px;
    display: inline-block;
    vertical-align: text-top;
    width: 20px;
    height: 20px;
    border-radius: 3px;
    background: #fff;
    border: 1px solid #42e5aa; }
  .first-screen-wrapper .first-screen-block .first-screen-checkboox [type='checkbox']:hover + label:before, .contact-section .contact-section-inputs .checkbox-input [type='checkbox']:hover + label:before {
    cursor: pointer; }
  .first-screen-wrapper .first-screen-block .first-screen-checkboox [type='checkbox']:checked + label::before, .contact-section .contact-section-inputs .checkbox-input [type='checkbox']:checked + label::before {
    background: #42e5aa; }
  .first-screen-wrapper .first-screen-block .first-screen-checkboox [type='checkbox']:checked + label:after, .contact-section .contact-section-inputs .checkbox-input [type='checkbox']:checked + label:after {
    content: '';
    position: absolute;
    left: 6px;
    top: 10px;
    background: #fff;
    width: 2px;
    height: 2px;
    box-shadow: 2px 0 0 #fff, 4px 0 0 #fff, 4px -2px 0 #fff, 4px -4px 0 #fff, 4px -6px 0 #fff, 4px -8px 0 #fff;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg); }
  @media (max-width: 768px) {
    .first-screen-wrapper .first-screen-block .first-screen-checkboox, .contact-section .contact-section-inputs .checkbox-input {
      margin-top: 30px; } }

.checkout-header {
  position: relative;
  display: flex;
  align-items: center;
  max-height: 115px; }
  @media (max-width: 768px) {
    .checkout-header {
      margin: 0 auto;
      max-width: 335px;
      margin-top: 20px;
      margin-bottom: 30px;
      border: 1px solid #e9e9e9 !important;
      border-radius: 5px;
      padding: 20px !important;
      max-height: 80px; } }
  @media (max-width: 768px) {
    .checkout-header .logo-block {
      display: none; } }
  .checkout-header .left-part {
    display: flex;
    align-items: center; }
    @media (max-width: 768px) {
      .checkout-header .left-part .text-block p {
        margin: 0; } }
    @media (max-width: 768px) {
      .checkout-header .left-part .text-block .text-block-title.mobile {
        display: block;
        margin-bottom: 8px; } }
    @media (max-width: 768px) {
      .checkout-header .left-part .text-block {
        margin-left: 0; } }
  @media (max-width: 768px) {
    .checkout-header .right-part {
      position: absolute;
      top: 5px;
      right: 20px; } }
  .checkout-header .right-part .right-mobile-edit {
    display: none; }
    @media (max-width: 768px) {
      .checkout-header .right-part .right-mobile-edit {
        display: inline-block;
        margin-right: 0; } }
  @media (max-width: 768px) {
    .checkout-header .right-part .right-edit {
      display: none; } }

@media (max-width: 768px) {
  .order-page-wrapper .hero-inner {
    box-shadow: 0 5px 12px 0 rgba(187, 201, 192, 0.3); } }

.order-page-wrapper .hero-image {
  max-height: 100px;
  max-height: 100px;
  min-height: 100%;
  padding: 20px 0;
  box-shadow: none;
  margin: 35px 0; }
  @media (max-width: 768px) {
    .order-page-wrapper .hero-image {
      padding: 0 !important;
      max-height: 150px !important;
      box-shadow: 0 5px 12px 0 rgba(187, 201, 192, 0.3);
      margin: 0; } }
  .order-page-wrapper .hero-image .hero-img-wrapper {
    z-index: -1; }

.order-page-wrapper .dobbi-logo {
  width: 110px;
  height: 45px; }

.order-page-wrapper .shadow-container {
  display: none; }

.order-page-wrapper .error-wrapper {
  text-align: center;
  margin-top: 250px;
  margin-bottom: 210px; }
  @media (max-width: 425px) {
    .order-page-wrapper .error-wrapper {
      margin-top: 90px;
      margin-bottom: 50px; } }

.order-page-wrapper .error-block {
  flex-wrap: wrap; }
  @media (max-width: 768px) {
    .order-page-wrapper .error-block {
      flex-direction: column; } }
  .order-page-wrapper .error-block img {
    margin-right: 82px; }
    @media (max-width: 768px) {
      .order-page-wrapper .error-block img {
        margin-right: 0; } }
  .order-page-wrapper .error-block .error-text {
    text-align: left; }
    @media (max-width: 425px) {
      .order-page-wrapper .error-block .error-text h1 {
        text-align: center;
        font-size: 2rem; } }
    @media (max-width: 425px) {
      .order-page-wrapper .error-block .error-text p {
        text-align: center;
        font-size: 1.5rem; } }

.order-page-wrapper .lng-switcher {
  border: 1px solid #d9d9d9;
  background-color: rgba(229, 236, 243, 0.4);
  border-radius: 30px; }
  .order-page-wrapper .lng-switcher .lang-item.active {
    border: 1px solid #e5ecf3; }
  .order-page-wrapper .lng-switcher .lang-item span {
    color: #000; }

.order-page-wrapper .main-order-block {
  background: linear-gradient(180deg, #12dce7 0%, #72ee6d 100%);
  align-items: baseline; }
  @media (max-width: 768px) {
    .order-page-wrapper .main-order-block {
      height: inherit; } }
  .order-page-wrapper .main-order-block .order-header {
    padding: 18px 34px; }
    @media (max-width: 768px) {
      .order-page-wrapper .main-order-block .order-header .logo-block {
        margin: 10px auto !important; } }
    .order-page-wrapper .main-order-block .order-header .text-block.with-breadcrumbs {
      display: flex;
      align-items: center; }
      @media (max-width: 768px) {
        .order-page-wrapper .main-order-block .order-header .text-block.with-breadcrumbs {
          margin: 0 auto !important; } }
    .order-page-wrapper .main-order-block .order-header .breadcrumbs-container {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: center; }
      @media (max-width: 768px) {
        .order-page-wrapper .main-order-block .order-header .breadcrumbs-container {
          margin: 0 auto !important; } }
      @media (max-width: 768px) {
        .order-page-wrapper .main-order-block .order-header .breadcrumbs-container p, .order-page-wrapper .main-order-block .order-header .breadcrumbs-container div {
          display: none; } }
    .order-page-wrapper .main-order-block .order-header p, .order-page-wrapper .main-order-block .order-header a, .order-page-wrapper .main-order-block .order-header div {
      display: flex;
      align-items: center;
      margin-right: 10px; }
    .order-page-wrapper .main-order-block .order-header a {
      font-family: Baufra Medium, sans-serif;
      font-size: 1.6rem;
      line-height: 1.43;
      color: #4e8af3; }
      .order-page-wrapper .main-order-block .order-header a:hover {
        color: rgba(78, 138, 243, 0.77); }

.order-block {
  height: 100%;
  margin-top: 100px;
  margin-bottom: 100px;
  max-width: 1164px;
  width: 100%;
  border-radius: 5px;
  background-color: #ffffff;
  box-shadow: 0 5px 42px 0 rgba(187, 201, 192, 0.3); }
  @media (max-width: 768px) {
    .order-block {
      height: 100%;
      border-radius: 0; } }
  @media (max-width: 320px) {
    .order-block {
      padding-left: 25px;
      padding-right: 25px; } }
  .order-block header {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #e9e9e9;
    padding: 34px; }
    @media (max-width: 768px) {
      .order-block header {
        border-bottom: none;
        flex-direction: column; } }
    .order-block header.checkout-header {
      display: flex;
      justify-content: space-between; }
    .order-block header .left-part {
      display: flex;
      align-items: center; }
    .order-block header .right-part span {
      margin-right: 14px;
      color: #42e5aa;
      font-family: Baufra Semi, sans-serif;
      cursor: pointer; }
    .order-block header .text-block {
      margin-left: 20px; }
      @media (max-width: 768px) {
        .order-block header .text-block .text-block-title {
          display: none; } }
      .order-block header .text-block .text-block-title.mobile {
        display: none; }
      .order-block header .text-block .bold-text {
        font-family: Baufra Semi, sans-serif; }
  .order-block .order-block-body {
    padding: 48px;
    display: flex;
    justify-content: space-around; }
    @media (max-width: 768px) {
      .order-block .order-block-body {
        padding-top: 0; } }
    @media (max-width: 425px) {
      .order-block .order-block-body {
        padding: 0; } }

.first-screen-inputs {
  display: flex;
  flex-direction: row; }
  @media (max-width: 768px) {
    .first-screen-inputs {
      flex-direction: column; } }

.inputs-wrapper {
  max-width: 345px;
  margin-right: 30px; }
  @media (max-width: 768px) {
    .inputs-wrapper {
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      border-right: none !important;
      padding-right: 0 !important; } }
  .inputs-wrapper h1 {
    font-family: Baufra Semi, sans-serif;
    font-size: 18px; }
  .inputs-wrapper p {
    font-size: 14px;
    margin-bottom: 30px; }
  .inputs-wrapper input {
    display: block;
    margin: 16px 0;
    max-width: 280px;
    width: 100%;
    height: 38px;
    box-shadow: none;
    padding: 0 35px;
    font-family: Baufra Medium, sans-serif; }
    .inputs-wrapper input::placeholder {
      font-family: Baufra, sans-serif; }
  .inputs-wrapper button {
    display: inline-block;
    height: 52px;
    margin: 30px auto; }
  .inputs-wrapper .blue-button {
    margin-top: 0px;
    width: 100%;
    height: 38px;
    border-radius: 17px;
    background-color: #003366;
    border: 0px;
    flex-direction: column;
    justify-content: center;
    padding-left: 35px;
    box-shadow: none; }
    .inputs-wrapper .blue-button div {
      color: white; }

@media (max-width: 1024px) {
  .map-block {
    max-width: 600px; } }

@media (max-width: 768px) {
  .map-block {
    display: none; } }

@media (max-width: 768px) {
  .first-screen-wrapper {
    align-items: flex-start;
    max-width: 335px;
    margin: 0 auto;
    height: 100%; } }

.first-screen-wrapper .first-screen-block {
  justify-content: center;
  min-height: 600px;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 420px;
  text-align: center; }
  @media (max-width: 768px) {
    .first-screen-wrapper .first-screen-block {
      justify-content: start; } }
  .first-screen-wrapper .first-screen-block h1 {
    font-size: 18px;
    font-family: Baufra Semi, sans-serif; }
    @media (max-width: 768px) {
      .first-screen-wrapper .first-screen-block h1 {
        font-size: 14px; } }
  .first-screen-wrapper .first-screen-block p {
    font-size: 14px; }
  .first-screen-wrapper .first-screen-block input {
    margin-top: 30px;
    width: 280px;
    height: 38px;
    font-size: 13px;
    font-family: Baufra Medium, sans-serif;
    padding: 0 35px; }
    .first-screen-wrapper .first-screen-block input::placeholder {
      font-family: Baufra, sans-serif; }
  .first-screen-wrapper .first-screen-block .first-screen-checkboox {
    margin-top: 32px; }
    .first-screen-wrapper .first-screen-block .first-screen-checkboox label {
      font-size: 13px;
      color: #000; }
      .first-screen-wrapper .first-screen-block .first-screen-checkboox label a {
        color: #4e8af3; }
  .first-screen-wrapper .first-screen-block button {
    margin: 60px 0 30px 0;
    padding: 10px 55px;
    max-width: 226px;
    width: 100%;
    height: 48px; }
    @media (max-width: 768px) {
      .first-screen-wrapper .first-screen-block button {
        padding: 0; } }

.second-order-screen {
  justify-content: flex-start;
  align-items: stretch; }
  @media (max-width: 768px) {
    .second-order-screen {
      flex-direction: column; } }
  .second-order-screen .left-order-block {
    min-height: 500px;
    align-self: baseline;
    position: relative;
    width: 100%;
    height: 100%;
    padding: 50px 48px;
    overflow-y: scroll; }
    @media (max-width: 768px) {
      .second-order-screen .left-order-block {
        min-height: auto; } }
    @media (max-width: 768px) {
      .second-order-screen .left-order-block {
        padding: 0;
        max-height: 100%;
        overflow-y: visible; } }
  .second-order-screen .right-order-block {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    max-width: 390px;
    padding: 40px 50px 50px 50px;
    justify-content: center;
    border-left: 1px solid #e9e9e9; }
    @media (max-width: 768px) {
      .second-order-screen .right-order-block {
        border-left: none; } }
  .second-order-screen .current-step-wrapper {
    position: relative; }
    @media (max-width: 768px) {
      .second-order-screen .current-step-wrapper {
        display: none; } }
    .second-order-screen .current-step-wrapper:after {
      transition: top 0.4s;
      top: 3px;
      left: -1px;
      position: absolute;
      content: '';
      height: 49px;
      width: 49px;
      border-radius: 50%;
      background-image: url("../../../images/order/order-service-bullet-full.svg");
      background-size: contain;
      z-index: 2; }
    .second-order-screen .current-step-wrapper.second-step:after {
      top: 139px; }
    .second-order-screen .current-step-wrapper.third-step:after {
      top: 275px; }
    .second-order-screen .current-step-wrapper .current-service-item {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      position: relative;
      width: 100%;
      height: 56px;
      transition: color 0.4s; }
      .second-order-screen .current-step-wrapper .current-service-item:not(:last-child) {
        margin-bottom: 80px; }
        .second-order-screen .current-step-wrapper .current-service-item:not(:last-child):after {
          position: absolute;
          content: '';
          height: 130px;
          width: 1px;
          background: #4e8af3;
          left: 24px;
          bottom: -108px; }
      .second-order-screen .current-step-wrapper .current-service-item.active .bullet-text-block span {
        color: #4e8af3; }
      .second-order-screen .current-step-wrapper .current-service-item .bullet-block {
        position: relative;
        z-index: 2;
        min-width: 48px;
        min-height: 48px; }
      .second-order-screen .current-step-wrapper .current-service-item .bullet-text-block {
        margin-left: 20px; }
        .second-order-screen .current-step-wrapper .current-service-item .bullet-text-block span {
          display: inline-block;
          color: #d9d9d9;
          font-size: 13px;
          font-family: Baufra, sans-serif; }
          .second-order-screen .current-step-wrapper .current-service-item .bullet-text-block span:first-child {
            font-family: Baufra Semi, sans-serif;
            font-size: 15px; }
  .second-order-screen .select-step-buttons {
    margin-top: 140px; }
    @media (max-width: 768px) {
      .second-order-screen .select-step-buttons {
        margin-top: 0px; } }
    .second-order-screen .select-step-buttons button.back-order-button {
      position: relative;
      min-width: 44px;
      min-height: 44px;
      padding: 0; }
      .second-order-screen .select-step-buttons button.back-order-button img {
        left: 22px; }
    .second-order-screen .select-step-buttons button.next-order-button {
      margin-left: 20px;
      width: 220px; }
      @media (max-width: 768px) {
        .second-order-screen .select-step-buttons button.next-order-button {
          min-width: 230px; } }

.first-option-block h2 {
  margin: 0;
  font-family: Baufra Semi, sans-serif;
  font-size: 18px; }

.first-option-block p {
  margin: 0;
  margin-top: 10px;
  font-family: Baufra, sans-serif;
  font-size: 13px; }

.first-option-block .order-option {
  position: relative;
  height: 100px;
  border: 1px solid #e9e9e9;
  border-radius: 5px;
  padding: 50px 40px;
  margin: 18px 0; }
  @media (max-width: 1024px) {
    .first-option-block .order-option {
      padding: 50px 5px; } }
  @media (max-width: 768px) {
    .first-option-block .order-option {
      height: 100px;
      padding: 20px; } }
  .first-option-block .order-option.checked {
    border: 1px solid #42e5aa; }
    .first-option-block .order-option.checked span {
      color: #42e5aa; }
  .first-option-block .order-option .left-option-block {
    min-width: 60px; }
    @media (max-width: 768px) {
      .first-option-block .order-option .left-option-block {
        margin-right: 15px; } }
    .first-option-block .order-option .left-option-block img {
      max-width: 60px;
      max-height: 85px;
      width: 100%; }
      @media (max-width: 768px) {
        .first-option-block .order-option .left-option-block img {
          width: 60px; } }
  .first-option-block .order-option .right-option-block {
    margin-left: 25px; }
    @media (max-width: 768px) {
      .first-option-block .order-option .right-option-block {
        margin-left: 5px; } }
    .first-option-block .order-option .right-option-block span {
      display: inline-block; }
      @media (max-width: 768px) {
        .first-option-block .order-option .right-option-block span {
          font-size: 14px !important; } }
    .first-option-block .order-option .right-option-block .option-title {
      font-size: 15px;
      font-family: Baufra Semi, sans-serif;
      margin-bottom: 12px; }
    .first-option-block .order-option .right-option-block .option-descr {
      font-size: 15px;
      font-family: Baufra, sans-serif; }
  .first-option-block .order-option .order-check-mark {
    position: absolute;
    top: 20px;
    right: 20px; }
    @media (max-width: 768px) {
      .first-option-block .order-option .order-check-mark {
        top: 10px;
        right: 10px; } }

.first-option-block .order-note {
  position: relative;
  display: block;
  padding: 20px 40px;
  height: 100%; }
  @media (max-width: 768px) {
    .first-option-block .order-note {
      padding: 20px; } }
  .first-option-block .order-note .order-note-title {
    font-size: 15px;
    font-family: Baufra Semi, sans-serif;
    margin-bottom: 12px; }
    @media (max-width: 768px) {
      .first-option-block .order-note .order-note-title {
        margin-bottom: 6px; } }
  .first-option-block .order-note .order-textarea-wrapper textarea {
    display: block;
    outline: none;
    border: none;
    width: 100%;
    resize: none; }
    .first-option-block .order-note .order-textarea-wrapper textarea::placeholder {
      font-size: 14px; }
  .first-option-block .order-note span {
    position: absolute;
    top: 20px;
    right: 20px;
    opacity: 0.5;
    color: #000000;
    font-family: Baufra, sans-serif;
    font-size: 13px; }
    @media (max-width: 768px) {
      .first-option-block .order-note span {
        top: 10px;
        right: 10px; } }

.mobile-order-steps {
  display: none;
  position: relative;
  margin: 0 auto;
  margin-bottom: 30px;
  height: 28px;
  width: 165px;
  border-radius: 14px;
  background-color: #ffffff;
  box-shadow: 0 2px 4px 0 rgba(0, 35, 178, 0.1); }
  @media (max-width: 768px) {
    .mobile-order-steps {
      display: block; } }
  .mobile-order-steps img {
    position: absolute;
    top: 8px; }
    .mobile-order-steps img:nth-child(1) {
      left: 18px; }
    .mobile-order-steps img:nth-child(3) {
      right: 18px; }
  .mobile-order-steps:before {
    position: absolute;
    content: '';
    height: 2px;
    width: 119px;
    background: #4e8af3;
    opacity: 0.2; }

.second-option-block {
  max-width: 620px;
  width: 100%; }
  .second-option-block h2 {
    margin: 0;
    font-family: Baufra Semi, sans-serif;
    font-size: 18px; }
    @media (max-width: 768px) {
      .second-option-block h2 {
        text-align: center; } }
  .second-option-block p {
    margin: 0;
    margin-top: 10px;
    font-family: Baufra, sans-serif;
    font-size: 13px;
    margin-bottom: 20px; }
    @media (max-width: 768px) {
      .second-option-block p {
        text-align: center; } }
  .second-option-block .dropdown-blocs {
    display: flex;
    justify-content: space-between; }

.dropdown-disabled span {
  color: grey !important; }

.dropdown-wrapper {
  position: relative;
  max-width: 290px;
  width: 100%;
  height: 74px;
  transition: 0.2s;
  border: 1px solid #e9e9e9;
  padding: 20px 26px;
  border-radius: 5px;
  cursor: pointer; }
  .dropdown-wrapper.opened-dropdown {
    border: 1px solid #42e5aa; }
    .dropdown-wrapper.opened-dropdown img {
      transition: 0.2s;
      transform: rotate(0); }
  .dropdown-wrapper > span {
    display: block;
    color: #42e5aa;
    font-family: Baufra, sans-serif;
    font-size: 15px; }
    .dropdown-wrapper > span.dropdown-title {
      font-family: Baufra Semi, sans-serif;
      margin-bottom: 2px; }
  .dropdown-wrapper > img {
    transition: 0.2s;
    position: absolute;
    right: 20px;
    transform: rotate(180deg); }
  .dropdown-wrapper .dropdown-loader {
    transition: 0.2s;
    position: absolute;
    right: 13px;
    top: 40px; }
  .dropdown-wrapper .dropdown-list {
    position: absolute;
    left: 0;
    top: calc(100% + 10px);
    display: none;
    list-style-type: none;
    max-width: 145px;
    max-height: 220px;
    margin: 0;
    border-radius: 10px;
    background-color: #ffffff;
    box-shadow: 0 2px 14px 0 rgba(0, 35, 178, 0.1), 0 2px 14px 0 rgba(0, 35, 178, 0.1);
    overflow-y: scroll;
    padding: 0; }
    .dropdown-wrapper .dropdown-list li {
      transition: color 0.2s;
      width: 100%;
      display: block;
      font-size: 13px;
      font-family: Baufra, sans-serif;
      padding: 10px 26px; }
      .dropdown-wrapper .dropdown-list li:hover {
        transition: 0.2s;
        color: #42e5aa; }
      .dropdown-wrapper .dropdown-list li:first-child {
        padding-top: 20px; }
      .dropdown-wrapper .dropdown-list li:last-child {
        padding-bottom: 20px; }
    .dropdown-wrapper .dropdown-list.opened-list {
      display: block; }

.avaliable-times {
  display: flex;
  justify-content: space-between; }

.hours-block {
  max-width: 290px;
  width: 100%;
  padding: 0;
  list-style-type: none;
  display: flex;
  flex-wrap: wrap; }
  .hours-block .hours-item {
    height: 50px;
    max-width: 60px;
    width: 25%;
    border: 1px solid rgba(53, 93, 255, 0.1);
    border-radius: 8px;
    flex-direction: column;
    background: #fff0; }
    .hours-block .hours-item:not(:last-child) {
      margin-right: 15px;
      margin-bottom: 15px; }
    .hours-block .hours-item:nth-child(4n) {
      margin-right: 0; }
    .hours-block .hours-item span {
      display: block;
      font-size: 13px;
      font-family: Baufra Semi, sans-serif;
      color: #b9b9b9; }
    .hours-block .hours-item.avaliable span {
      color: #4e8af3; }
    .hours-block .hours-item.undefined {
      border: none; }
    .hours-block .hours-item.pickup-items.selected {
      background: linear-gradient(180deg, #f7c722 0%, #fb566f 100%);
      border-top: 1px solid #fff; }
      .hours-block .hours-item.pickup-items.selected span {
        color: #fff; }
    .hours-block .hours-item.delivery-items.selected {
      background: linear-gradient(180deg, #12dce7 0%, #1dcde9 0.58%, #8c35ff 100%);
      border-top: 1px solid #fff; }
      .hours-block .hours-item.delivery-items.selected span {
        color: #fff; }

@media (max-width: 768px) {
  .second-option-desktop {
    display: none; } }

.second-option-mobile {
  display: none;
  justify-content: space-between;
  margin-bottom: 50px; }
  @media (max-width: 768px) {
    .second-option-mobile {
      display: flex; } }
  .second-option-mobile .mobile-select-time {
    border: 1px solid #e9e9e9;
    border-radius: 5px;
    height: 98px;
    width: 100%;
    max-width: 164px;
    padding: 14px 22px; }
    @media (max-width: 320px) {
      .second-option-mobile .mobile-select-time {
        max-width: 134px; } }
    .second-option-mobile .mobile-select-time span {
      display: inline-block;
      font-size: 13px;
      font-family: Baufra, sans-serif; }
      .second-option-mobile .mobile-select-time span.bold-select-time {
        font-size: 14px;
        font-family: Baufra Semi, sans-serif;
        margin-bottom: 10px; }
    .second-option-mobile .mobile-select-time.selected-pickup {
      background: linear-gradient(180deg, #f7c722 0%, #fb566f 100%); }
      .second-option-mobile .mobile-select-time.selected-pickup span {
        color: #fff; }
    .second-option-mobile .mobile-select-time.selected-delivery {
      background: linear-gradient(180deg, #12dce7 0%, #1dcde9 0.58%, #8c35ff 100%); }
      .second-option-mobile .mobile-select-time.selected-delivery span {
        color: #fff; }
  .second-option-mobile .select-date-modal-wrapper {
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.4); }
    .second-option-mobile .select-date-modal-wrapper .select-date-modal-content {
      position: absolute;
      bottom: 10px;
      height: calc(100% - 10px);
      max-height: 554px;
      width: 355px;
      border-radius: 10px;
      background-color: #ffffff; }
      @media (max-width: 320px) {
        .second-option-mobile .select-date-modal-wrapper .select-date-modal-content {
          max-width: 290px; } }
      .second-option-mobile .select-date-modal-wrapper .select-date-modal-content .select-date-header {
        position: relative;
        padding: 20px;
        border-bottom: 2px solid #e5ecf3; }
        .second-option-mobile .select-date-modal-wrapper .select-date-modal-content .select-date-header span {
          font-size: 13px;
          font-family: Baufra Semi, sans-serif; }
        .second-option-mobile .select-date-modal-wrapper .select-date-modal-content .select-date-header .select-date-close-button {
          position: absolute;
          top: 20px;
          right: 20px; }
      .second-option-mobile .select-date-modal-wrapper .select-date-modal-content .select-date-body {
        max-height: 490px;
        overflow-y: scroll;
        padding: 20px; }
        .second-option-mobile .select-date-modal-wrapper .select-date-modal-content .select-date-body .modal-date-block > p {
          text-align: left;
          color: rgba(217, 217, 217, 0.6);
          font-family: Baufra Semi, sans-serif; }
        .second-option-mobile .select-date-modal-wrapper .select-date-modal-content .select-date-body .modal-date-block .modal-hours-wrapper {
          display: flex;
          flex-wrap: wrap; }
          .second-option-mobile .select-date-modal-wrapper .select-date-modal-content .select-date-body .modal-date-block .modal-hours-wrapper .hour-block {
            flex-direction: column;
            height: 50px;
            width: 61px;
            border: 1px solid rgba(53, 93, 255, 0.1);
            border-radius: 8px;
            margin-right: 13px;
            margin-bottom: 13px; }
            .second-option-mobile .select-date-modal-wrapper .select-date-modal-content .select-date-body .modal-date-block .modal-hours-wrapper .hour-block.available span {
              color: #4e8af3; }
            .second-option-mobile .select-date-modal-wrapper .select-date-modal-content .select-date-body .modal-date-block .modal-hours-wrapper .hour-block span {
              display: block;
              color: #b9b9b9;
              font-family: Baufra Semi, sans-serif;
              font-size: 12px; }
              .second-option-mobile .select-date-modal-wrapper .select-date-modal-content .select-date-body .modal-date-block .modal-hours-wrapper .hour-block span:first-child {
                margin-bottom: 2px; }

.selected-date-time span {
  display: block !important; }

.overview-section {
  border: 1px solid #e9e9e9;
  border-radius: 5px;
  padding: 20px 40px; }
  @media (max-width: 768px) {
    .overview-section {
      padding: 20px; } }
  .overview-section:not(:last-child) {
    margin-bottom: 14px; }
  .overview-section .overview-section-title {
    font-family: Baufra Semi, sans-serif;
    font-size: 13px;
    margin: 0; }
    .overview-section .overview-section-title:not(:last-child) {
      margin-bottom: 12px; }

.third-option-block .third-block-header {
  font-size: 18px;
  font-family: Baufra Semi, sans-serif;
  margin-bottom: 20px;
  margin-top: 0; }
  @media (max-width: 768px) {
    .third-option-block .third-block-header {
      text-align: center; } }

.third-option-block .selected-services {
  font-style: 13px;
  display: flex; }
  .third-option-block .selected-services .overview-service:not(:last-child) {
    margin-right: 56px; }
  .third-option-block .selected-services .overview-service span {
    display: block;
    font-size: 13px; }
    .third-option-block .selected-services .overview-service span.service-section-title {
      margin-bottom: 10px;
      font-family: Baufra Semi, sans-serif; }

.third-option-block .note-textarea {
  width: 100%;
  height: 45px;
  border: none;
  resize: none;
  outline: none; }
  .third-option-block .note-textarea::placeholder {
    font-size: 13px; }

.third-option-block .promocode-input {
  border: none;
  outline: none; }
  .third-option-block .promocode-input::placeholder {
    font-size: 13px; }

.payment-section {
  background-color: #6c5ff8; }
  .payment-section p {
    color: #fff;
    font-size: 14px;
    font-family: Baufra, sans-serif; }

.contact-section {
  padding: 40px; }
  @media (max-width: 768px) {
    .contact-section {
      margin-top: 50px;
      padding: 0;
      border: 0;
      text-align: center; } }
  .contact-section .contact-section-title {
    font-family: Baufra Semi, sans-serif;
    font-size: 18px;
    margin: 0; }
  .contact-section .contact-section-input-data {
    margin-bottom: 18px;
    flex-direction: row;
    display: flex;
    justify-content: flex-start; }
    .contact-section .contact-section-input-data .contact-section-input-data-image {
      width: 25px; }
  .contact-section p {
    font-size: 13px;
    font-family: Baufra, sans-serif; }
  .contact-section .contact-section-inputs {
    display: flex;
    flex-direction: column; }
    .contact-section .contact-section-inputs input {
      max-width: 280px;
      height: 40px;
      margin-bottom: 14px;
      font-size: 13px;
      font-family: Baufra Semi, sans-serif;
      box-shadow: none; }
      .contact-section .contact-section-inputs input:first-child {
        margin-top: 20px; }
      .contact-section .contact-section-inputs input::placeholder {
        font-size: 13px;
        font-family: Baufra Semi, sans-serif; }
      .contact-section .contact-section-inputs input:not(:last-child) {
        margin-bottom: 14px; }
    .contact-section .contact-section-inputs .checkbox-input {
      margin-top: 32px; }
      .contact-section .contact-section-inputs .checkbox-input label {
        font-size: 13px;
        font-family: Baufra Semi, sans-serif; }

.reset-password {
  color: #4e8af3;
  cursor: pointer;
  font-size: 14px;
  margin-left: 36px; }

.fourth-order-block {
  max-width: 740px;
  max-height: 655px; }
  @media (max-width: 320px) {
    .fourth-order-block {
      padding: 0; } }

.fourth-screen-wrapper {
  padding: 10px;
  border-radius: 5px; }
  @media (max-width: 425px) {
    .fourth-screen-wrapper {
      padding: 0; } }
  .fourth-screen-wrapper .congratulation-block {
    flex-direction: column;
    width: 100%;
    border-radius: 5px;
    background: linear-gradient(178.79deg, #12dce7 0%, #8c35ff 100%);
    height: 270px;
    color: #fff; }
    @media (max-width: 425px) {
      .fourth-screen-wrapper .congratulation-block {
        border-radius: 0; } }
    .fourth-screen-wrapper .congratulation-block h2 {
      max-width: 300px;
      font-size: 20px;
      font-family: Baufra Semi, sans-serif;
      text-align: center;
      margin: 0;
      margin-bottom: 14px; }
      @media (max-width: 425px) {
        .fourth-screen-wrapper .congratulation-block h2 {
          font-size: 17px; } }
    .fourth-screen-wrapper .congratulation-block p {
      margin: 0;
      font-family: Baufra Semi, sans-serif;
      font-size: 15px;
      margin-bottom: 40px; }
      @media (max-width: 425px) {
        .fourth-screen-wrapper .congratulation-block p {
          font-size: 14px; } }
    .fourth-screen-wrapper .congratulation-block .fourth-screen-faq-button {
      padding: 22px;
      justify-content: flex-start;
      height: 62px;
      width: 345px;
      border-radius: 5px;
      background-color: #ffffff;
      box-shadow: 0 -2px 4px 0 rgba(0, 0, 0, 0.04), 0 2px 4px 0 rgba(0, 0, 0, 0.08); }
      @media (max-width: 425px) {
        .fourth-screen-wrapper .congratulation-block .fourth-screen-faq-button {
          width: 270px; } }
      .fourth-screen-wrapper .congratulation-block .fourth-screen-faq-button span {
        margin-left: 22px;
        color: #4e8af3;
        font-family: Baufra Semi, sans-serif; }
  .fourth-screen-wrapper .download-app-block {
    padding: 50px;
    flex-direction: column;
    width: 100%;
    height: 100%; }
    .fourth-screen-wrapper .download-app-block h2 {
      max-width: 300px;
      font-size: 20px;
      font-family: Baufra Semi, sans-serif;
      text-align: center;
      margin: 0;
      margin-bottom: 14px;
      text-align: center; }
      @media (max-width: 425px) {
        .fourth-screen-wrapper .download-app-block h2 {
          font-size: 17px; } }
    .fourth-screen-wrapper .download-app-block p {
      margin: 0;
      font-family: Baufra Semi, sans-serif;
      font-size: 15px;
      margin-bottom: 40px;
      max-width: 450px;
      text-align: center; }
      @media (max-width: 425px) {
        .fourth-screen-wrapper .download-app-block p {
          font-size: 14px; } }
    .fourth-screen-wrapper .download-app-block .qr-order {
      width: 135px;
      height: 135px; }
      @media (max-width: 768px) {
        .fourth-screen-wrapper .download-app-block .qr-order {
          display: none; } }
    .fourth-screen-wrapper .download-app-block .app-links {
      display: none; }
      @media (max-width: 768px) {
        .fourth-screen-wrapper .download-app-block .app-links {
          display: block;
          margin-top: 0; }
          .fourth-screen-wrapper .download-app-block .app-links a {
            margin-left: 0; } }

.has-bag-block {
  border: 1px solid #d3d3d3;
  border-radius: 4px;
  padding: 22px;
  margin-bottom: 50px; }
  .has-bag-block .hidden {
    display: none !important; }
  .has-bag-block .has-bag-header {
    font-size: 14px;
    font-weight: bold; }
  .has-bag-block ul {
    padding-left: 15px !important;
    margin-top: 0px; }
  .has-bag-block li {
    font-size: 10px;
    font-weight: normal;
    line-height: 2; }
  .has-bag-block .has-bag-info {
    margin-top: 30px;
    display: flex;
    flex-direction: row;
    justify-content: space-between; }
  .has-bag-block .has-bag-select {
    font-size: 12px;
    font-weight: 700;
    line-height: 2;
    color: #003366;
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    align-items: center;
    cursor: pointer; }
    .has-bag-block .has-bag-select svg {
      width: 15px;
      height: 15px;
      margin-right: 8px; }
    .has-bag-block .has-bag-select img {
      width: 15px;
      height: 15px;
      color: grey; }
    .has-bag-block .has-bag-select span {
      display: flex; }
    .has-bag-block .has-bag-select span.selected {
      fill: #4e8af3; }
  .has-bag-block .has-bag-image img {
    width: 184px; }
  @media (max-width: 1024px) {
    .has-bag-block .has-bag-image {
      display: none; } }

.text-block.text-beta-block {
  display: flex;
  margin-left: auto !important;
  margin-right: 95px;
  margin-top: 0;
  margin-bottom: 0;
  order: 2;
  font-size: 13px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  width: 255px; }

@-moz-document url-prefix() {
  .text-block.text-beta-block {
    margin-right: 80px; } }
  @media (max-width: 1119px) {
    .text-block.text-beta-block {
      margin-right: 80px; } }
  @media (max-width: 890px) {
    .text-block.text-beta-block {
      margin-right: 10px; } }
  @media (max-width: 800px) {
    .text-block.text-beta-block {
      margin-right: 0; } }
  @media (max-width: 790px) {
    .text-block.text-beta-block {
      width: 245px; } }
  @media (max-width: 768px) {
    .text-block.text-beta-block {
      margin: 0 auto;
      width: auto; } }
  @media (max-width: 330px) {
    .text-block.text-beta-block {
      font-size: 10px; } }
  .text-block.text-beta-block .text-beta {
    text-transform: uppercase;
    color: #ffffff;
    background-color: #4e8af3;
    padding: 12px 7px; }
  .text-block.text-beta-block .beta {
    position: relative;
    color: #d0021b;
    line-height: 9px;
    margin-left: 10px; }

@media (max-width: 768px) {
  .order-block-body {
    text-align: center; } }

@media (max-width: 768px) {
  #step-1-recover-input-email {
    display: inline-block; } }

.has-bag-info ul li {
  font-size: 12px; }

#order-user-create-account input[type="password"] {
  font-family: inherit; }

#order-user-create-account button {
  box-shadow: 0 2px 4px 0 rgba(52, 75, 73, 0.5);
  border: solid 1px #e9e9e4;
  background-color: #42e5aa;
  background-image: none; }
  #order-user-create-account button:active {
    box-shadow: 0 5px 6px 0 rgba(28, 66, 75, 0.5) !important;
    background-color: #42dda2; }
  #order-user-create-account button:hover {
    box-shadow: 0 3px 4px 0 rgba(36, 73, 75, 0.5); }
  #order-user-create-account button:disabled {
    background-color: rgba(63, 208, 152, 0.46); }
  #order-user-create-account button div {
    color: #ffffff; }
