.modal-inner .accept-button, .modal-inner .check-form-submit {
  display: inline-block;
  border-radius: 30px;
  background-color: #fff;
  box-shadow: 0 4px 25px 0 rgba(83, 170, 112, 0.2);
  font-size: 1.5rem;
  font-family: Baufra Semi, sans-serif;
  text-align: center;
  padding: 20px 35px;
  color: #42e5aa;
  cursor: pointer;
  outline: none;
  box-shadow: 0 4px 12px 0 rgba(53, 93, 255, 0.2);
  border: 0; }

.modal-inner-second {
  display: flex;
  justify-content: center;
  align-items: center; }

.modal-inner input {
  display: inline-block;
  border-radius: 17px;
  background-color: #fff;
  box-shadow: 0 4px 25px 0 rgba(83, 170, 112, 0.2);
  padding: 18px 35px;
  outline: none;
  border: 1px solid #979797;
  color: #000000; }

.modal-inner .accept-button {
  font-family: Baufra Semi, sans-serif;
  color: #4a90e2;
  padding: 18px 35px;
  max-height: 55px;
  box-shadow: 0 2px 4px 0 rgba(52, 75, 73, 0.5);
  border: 1px solid #e9e9e4;
  min-width: 177px;
  transition: box-shadow 0.1s; }
  .modal-inner .checked.accept-button {
    transition: background-image 0.3s;
    background-image: linear-gradient(-180deg, #12dce7 0%, #72ee6d 100%);
    box-shadow: 0 4px 12px 0 rgba(0, 244, 81, 0.4);
    font-size: 15px;
    color: #ffffff;
    border: rgba(187, 201, 192, 0);
    letter-spacing: 0;
    text-align: center; }
  .modal-inner .accept-button:hover {
    box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.2);
    transition: box-shadow 0.1s; }
  .modal-inner .accept-button:active {
    border: #bbc9c0;
    box-shadow: inset 0 1px 12px 0 rgba(0, 0, 0, 0.4); }
  @media (max-width: 768px) {
    .modal-inner .accept-button {
      max-width: 140px;
      margin: 0 auto;
      padding: 15px 30px;
      text-align: center;
      max-width: 160px;
      min-width: 130px; } }
  @media (max-width: 425px) {
    .modal-inner .accept-button {
      max-width: 185px; } }

.modal-wrapper {
  max-width: 430px; }

.ReactModal__Content {
  padding: 0 !important;
  border: none !important;
  box-shadow: none !important;
  background: none !important; }

.close-button {
  color: #42E5AA;
  background: #fff; }

.devider {
  height: 1px;
  width: 100%;
  border: 1px solid #E9E9E9; }
  @media (max-width: 768px) {
    .devider {
      display: none; } }

@media (max-width: 768px) {
  .modal-wrapper {
    padding: 10px; } }

.modal-inner {
  background: white;
  box-shadow: 0 5px 42px 0 rgba(187, 201, 192, 0.3);
  border-radius: 10px;
  padding: 50px 40px; }
  @media (max-width: 320px) {
    .modal-inner {
      max-height: 400px;
      overflow-y: scroll; } }
  .modal-inner.modal-full {
    align-items: baseline;
    flex-direction: column;
    display: flex;
    padding: 40px 50px; }
  .modal-inner h2 {
    font-size: 20px;
    line-height: 24px;
    font-family: Baufra Semi, sans-serif; }
  .modal-inner p {
    font-size: 15px;
    font-family: Baufra Semi, sans-serif span;
      font-family-display: inline-block;
      font-family-color: #42E5AA;
      font-family-text-decoration: underline;
      font-family-margin-top: 5px; }
  .modal-inner .accept-button {
    margin-top: 45px; }
  .modal-inner .app-links {
    justify-content: flex-start;
    margin-bottom: 50px; }
    .modal-inner .app-links a:first-child {
      margin-left: 0; }
    @media (max-width: 768px) {
      .modal-inner .app-links {
        margin: 0; } }
  .modal-inner .modal-qr {
    height: 135px;
    width: 135px; }
  .modal-inner .modal-text-bold {
    font-family: Baufra Semi, sans-serif;
    font-size: 16px;
    line-height: 25px;
    margin: 0; }
  .modal-inner .modal-text {
    font-size: 13px;
    margin: 0; }
  .modal-inner input {
    max-width: 124px;
    box-shadow: none;
    width: 100%;
    padding: 15px 35px;
    border: 1px solid #DFDFE4; }
  .modal-inner .email-input {
    width: 100%;
    max-width: 300px;
    margin-top: 42px;
    align-self: center; }
  .modal-inner .checkbox-input {
    margin-top: 32px;
    margin-top: 45px; }
    .modal-inner .checkbox-input [type="checkbox"] {
      margin-right: 18px;
      position: absolute;
      opacity: 0; }
    .modal-inner .checkbox-input label {
      color: #42E5AA;
      font-family: Baufra Semi, sans-serif;
      position: relative;
      cursor: pointer;
      padding: 0; }
      .modal-inner .checkbox-input label a {
        font-family: Baufra Semi, sans-serif;
        color: #42E5AA;
        text-decoration: underline; }
    .modal-inner .checkbox-input [type="checkbox"] + label {
      position: relative;
      cursor: pointer;
      padding: 0; }
    .modal-inner .checkbox-input [type="checkbox"] + label:before {
      transition: .5s;
      content: "";
      margin-right: 10px;
      display: inline-block;
      vertical-align: text-top;
      width: 20px;
      height: 20px;
      border-radius: 3px;
      background: #fff;
      border: 1px solid #42E5AA; }
    .modal-inner .checkbox-input [type="checkbox"]:hover + label:before {
      cursor: pointer; }
    .modal-inner .checkbox-input [type="checkbox"]:checked + label::before {
      background: #42E5AA; }
    .modal-inner .checkbox-input [type="checkbox"]:checked + label:after {
      content: "";
      position: absolute;
      left: 6px;
      top: 10px;
      background: #fff;
      width: 2px;
      height: 2px;
      box-shadow: 2px 0 0 #fff, 4px 0 0 #fff, 4px -2px 0 #fff, 4px -4px 0 #fff, 4px -6px 0 #fff, 4px -8px 0 #fff;
      -webkit-transform: rotate(45deg);
      transform: rotate(45deg); }
    @media (max-width: 768px) {
      .modal-inner .checkbox-input {
        margin-top: 30px; } }
  .modal-inner .check-form-submit {
    align-self: center;
    margin-top: 50px;
    margin-bottom: 40px;
    font-family: Baufra Semi, sans-serif;
    transition: box-shadow .3s;
    color: #4E8AF3;
    padding: 18px 55px;
    max-height: 55px;
    box-shadow: 0 4px 12px 0 rgba(53, 93, 255, 0.2); }
    .modal-inner .check-form-submit.checked {
      transition: .3s;
      background-image: linear-gradient(-180deg, #12DCE7 0%, #72EE6D 100%);
      box-shadow: 0 4px 12px 0 rgba(0, 244, 81, 0.4);
      color: #FFFFFF;
      border: rgba(187, 201, 192, 0);
      letter-spacing: 0;
      text-align: center; }
    .modal-inner .check-form-submit:hover {
      box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.2);
      transition: box-shadow .1s; }
    .modal-inner .check-form-submit:active {
      border: #bbc9c0;
      box-shadow: inset 0 1px 12px 0 rgba(0, 0, 0, 0.4); }
    @media (max-width: 768px) {
      .modal-inner .check-form-submit {
        max-width: 140px;
        margin: 0 auto;
        margin-top: 40px;
        padding: 15px 30px;
        text-align: center; } }

.modal-inner-second {
  padding-top: 50px;
  justify-content: space-between; }
  @media (max-width: 768px) {
    .modal-inner-second {
      display: none; } }

.modal-coockie {
  z-index: 100;
  padding: 60px 70px; }
  @media (max-width: 768px) {
    .modal-coockie {
      padding: 35px 15px;
      max-width: 355px; } }

.extended-block {
  background-color: #F1F5F8;
  margin-top: 30px;
  padding: 30px 20px; }
  @media (max-height: 800px) {
    .extended-block {
      max-height: 300px;
      overflow-y: scroll; } }
  @media (max-width: 768px) {
    .extended-block {
      max-height: 257px; } }
  .extended-block .extend-item:first-child {
    margin-bottom: 54px; }
  .extended-block .extend-item.last {
    display: flex;
    justify-content: space-between;
    align-items: center; }
    .extended-block .extend-item.last p {
      margin-right: 50px; }
  .extended-block .extend-item h2 {
    font-family: Baufra, sans-serif; }

.checkBoxs,
.checkBoxs .checkBox,
.checkBoxs .checkBox label {
  float: left;
  position: relative; }

.checkBoxs,
.checkBoxs .checkBox label {
  width: 100%;
  max-width: 100px; }

.checkBoxs .checkBox {
  width: 110px;
  padding: 0 20px;
  margin: 15px 0;
  margin-left: -20px; }

.checkBoxs .checkBox input {
  display: none; }

.checkBoxs .checkBox label,
.checkBoxs .checkBox label .circle {
  transition: 500ms cubic-bezier(0.4, 0, 0.2, 1);
  -webkit-transition: 500ms cubic-bezier(0.4, 0, 0.2, 1);
  -ms-transition: 500ms cubic-bezier(0.4, 0, 0.2, 1); }

.checkBoxs .checkBox label {
  height: 32px;
  background: #d8d8d8;
  border-radius: 25px;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.4);
  cursor: pointer; }

.checkBoxs .checkBox label .circle {
  width: 24px;
  height: 24px;
  position: absolute;
  top: 4px;
  left: 5px;
  border: none;
  background: #fff;
  border-radius: 50%;
  transform: translateX(0%);
  -webkit-transform: translateX(0%);
  -ms-transform: translateX(0%); }

.checkBoxs .checkBox.active label {
  background-color: #42E5AA; }

.checkBoxs .checkBox.active label .circle {
  background: #fff;
  transform: translateX(150%); }

.ReactModalPortal > * {
  opacity: 0; }

.ReactModal__Overlay {
  transition: opacity 200ms ease-in-out;
  background: rgba(0, 0, 0, 0.15); }
  .ReactModal__Overlay--after-open {
    opacity: 1; }
  .ReactModal__Overlay--before-close {
    opacity: 0; }

.ReactModal__Content {
  position: absolute !important;
  overflow-x: auto !important;
  overflow-y: auto !important; }

.ReactModal__Body--open {
  overflow: hidden;
  position: fixed;
  width: 100%; }
