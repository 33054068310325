.price-block {
  max-width: 1200px;
  padding: 0 20px;
  width: 100%;
  margin: 0 auto; }

.price-block {
  position: relative;
  z-index: 2;
  padding: 80px 100px;
  margin-top: -300px;
  border-radius: 5px;
  background-color: #ffffff;
  box-shadow: 0 5px 42px 0 rgba(187, 201, 192, 0.3); }

.price-block .tabs-header h2 {
  color: #000;
  font-family: "Baufra Semi", sans-serif;
  font-size: 4rem; }

.price-block .tabs-header p {
  color: #000;
  font-family: "Baufra Medium", sans-serif;
  font-size: 2rem;
  line-height: 1.4;
  text-align: right; }

@media (max-width: 425px) {
  .contact-page-wrapper .hero-image {
    min-height: 0; } }

@media (max-width: 768px) {
  .price-block {
    padding-bottom: 0; } }

@media (max-width: 425px) {
  .price-block {
    margin-top: 50px; } }

.price-block ul {
  margin: 0;
  padding: 0; }

.price-block .tabs-header {
  display: flex;
  align-content: center;
  align-items: baseline;
  text-align: center;
  justify-content: space-between;
  cursor: pointer;
  margin: -80px -100px 50px -100px; }
  @media (max-width: 768px) {
    .price-block .tabs-header {
      margin-bottom: 0;
      padding-bottom: 0; } }
  .price-block .tabs-header h2 {
    font-size: 2.5rem; }
    @media (max-width: 768px) {
      .price-block .tabs-header h2 {
        min-height: 56px;
        font-size: 15px;
        display: flex;
        align-items: center;
        justify-content: center; } }
  .price-block .tabs-header p {
    text-align: center;
    font-size: 1.8rem; }
    @media (max-width: 768px) {
      .price-block .tabs-header p {
        display: none; } }
  .price-block .tabs-header li {
    list-style: none;
    width: 50%;
    margin: 0;
    padding: 35px;
    outline: none; }
  .price-block .tabs-header li:not(.react-tabs__tab--selected) {
    background-color: #f7fbfa; }
    @media (max-width: 768px) {
      .price-block .tabs-header li:not(.react-tabs__tab--selected) {
        background-color: #fff; } }

.price-block .tab-inner {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between; }
  @media (max-width: 768px) {
    .price-block .tab-inner {
      flex-direction: column;
      margin: 0 -85px; } }
  .price-block .tab-inner .tab-inner_col {
    position: relative;
    flex: 1;
    margin: 76px; }
    @media (max-width: 768px) {
      .price-block .tab-inner .tab-inner_col {
        margin: 0; } }
    .price-block .tab-inner .tab-inner_col:after {
      position: absolute;
      content: "";
      right: -76px;
      top: 0;
      height: 316px;
      width: 1px;
      background-color: #e9e9e9; }
      @media (max-width: 768px) {
        .price-block .tab-inner .tab-inner_col:after {
          display: none;
          margin: 0 !important; } }
    .price-block .tab-inner .tab-inner_col:first-child {
      margin-left: 0; }
    .price-block .tab-inner .tab-inner_col:last-child {
      margin-right: 0; }
      .price-block .tab-inner .tab-inner_col:last-child:after {
        display: none; }
    .price-block .tab-inner .tab-inner_col p {
      max-width: 275px;
      padding: 8px 0;
      display: flex;
      justify-content: space-between;
      position: relative; }
      @media (max-width: 768px) {
        .price-block .tab-inner .tab-inner_col p {
          max-width: 100%; } }
      .price-block .tab-inner .tab-inner_col p span {
        flex: 3; }
        @media (max-width: 768px) {
          .price-block .tab-inner .tab-inner_col p span {
            flex: none; } }
        .price-block .tab-inner .tab-inner_col p span:first-child {
          max-width: 170px; }
        .price-block .tab-inner .tab-inner_col p span:last-child {
          flex: 1;
          white-space: nowrap; }
          @media (max-width: 768px) {
            .price-block .tab-inner .tab-inner_col p span:last-child {
              flex: none; } }
    .price-block .tab-inner .tab-inner_col p:first-child {
      margin-top: 0;
      padding-top: 0; }
  .price-block .tab-inner .ironed_tab p {
    max-width: 370px; }
    @media (max-width: 768px) {
      .price-block .tab-inner .ironed_tab p {
        max-width: 100%; } }

.price-block .react-tabs__tab--selected .tabs {
  position: relative; }
  .price-block .react-tabs__tab--selected .tabs:after {
    content: "";
    position: absolute;
    bottom: -10px;
    right: 50%;
    transform: translateX(50%);
    height: 2px;
    width: 171.5px;
    border-radius: 1px;
    background-color: #42e5aa; }
    @media (max-width: 768px) {
      .price-block .react-tabs__tab--selected .tabs:after {
        width: 128px; } }

.price-block .tab-tablet_header {
  position: relative;
  display: none;
  margin: 0 -85px;
  padding-bottom: 24px;
  margin-bottom: 24px; }
  .price-block .tab-tablet_header h2 {
    font-family: Helvetica;
    font-size: 20px;
    text-align: center; }
    @media (max-width: 425px) {
      .price-block .tab-tablet_header h2 {
        text-align: left; } }
  .price-block .tab-tablet_header p {
    font-size: 15px;
    color: #000000;
    text-align: center; }
    @media (max-width: 425px) {
      .price-block .tab-tablet_header p {
        text-align: left; } }
  .price-block .tab-tablet_header:after {
    position: absolute;
    content: "";
    width: calc(100% - 60px);
    height: 1px;
    left: 50%;
    background: #e5ecf3;
    transform: translateX(-50%); }
    @media (max-width: 425px) {
      .price-block .tab-tablet_header:after {
        left: 0;
        transform: none;
        widows: 260px; } }
  @media (max-width: 768px) {
    .price-block .tab-tablet_header {
      display: block; } }

.react-tabs {
  padding: 15px; }
