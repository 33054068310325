.hamburger-button {
  cursor: pointer;
  width: 28px;
  height: 24px;
  display: none;
  align-self: center; }
  @media (max-width: 768px) {
    .hamburger-button {
      display: block; } }
  .hamburger-button i {
    background-color: #42E5AA;
    border-radius: 3px;
    content: '';
    display: block;
    width: 100%;
    height: 3px; }
  .hamburger-button i:nth-child(1) {
    animation: outT 0.3s backwards;
    animation-direction: reverse; }
  .hamburger-button i:nth-child(2) {
    margin: 5px 0;
    animation: outM 0.3s backwards;
    animation-direction: reverse; }
  .hamburger-button i:nth-child(3) {
    animation: outBtm 0.3s backwards;
    animation-direction: reverse; }
  .hamburger-button.active i:nth-child(1) {
    animation: inT 0.3s forwards; }
  .hamburger-button.active i:nth-child(2) {
    animation: inM 0.3s forwards; }
  .hamburger-button.active i:nth-child(3) {
    animation: inBtm 0.3s forwards; }

@keyframes inM {
  50% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(45deg); } }

@keyframes outM {
  50% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(45deg); } }

@keyframes inT {
  0% {
    transform: translateY(0px) rotate(0deg); }
  50% {
    transform: translateY(8px) rotate(0deg); }
  100% {
    transform: translateY(8px) rotate(135deg); } }

@keyframes outT {
  0% {
    transform: translateY(0px) rotate(0deg); }
  50% {
    transform: translateY(8px) rotate(0deg); }
  100% {
    transform: translateY(8px) rotate(135deg); } }

@keyframes inBtm {
  0% {
    transform: translateY(0px) rotate(0deg); }
  50% {
    transform: translateY(-8px) rotate(0deg); }
  100% {
    transform: translateY(-8px) rotate(135deg); } }

@keyframes outBtm {
  0% {
    transform: translateY(0px) rotate(0deg); }
  50% {
    transform: translateY(-8px) rotate(0deg); }
  100% {
    transform: translateY(-8px) rotate(135deg); } }
