.download-app {
  max-width: 1200px;
  padding: 0 20px;
  width: 100%;
  margin: 0 auto; }

.download-app h1 {
  color: #000;
  font-family: "Baufra Semi", sans-serif;
  font-size: 4rem; }

.download-app p {
  color: #000;
  font-family: "Baufra Medium", sans-serif;
  font-size: 2rem;
  line-height: 1.4;
  text-align: right; }

.download-app {
  margin: 50px auto;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  margin-top: 150px; }
  @media (max-width: 1024px) {
    .download-app {
      flex-wrap: wrap;
      flex-direction: column-reverse !important; }
      .download-app .app-promo-img {
        margin-left: 0 !important; }
      .download-app .app-links {
        justify-content: center; } }
  @media (max-width: 768px) {
    .download-app .app-links a {
      margin: 0; }
      .download-app .app-links a:first-child {
        margin-right: 15px; }
    .download-app .download-text {
      padding-top: 0 !important;
      text-align: left; } }

@media (max-width: 768px) and (max-width: 425px) {
  .download-app .app-promo-img {
    width: 100%; } }
  @media (max-width: 768px) {
    .download-app p {
      text-align: left; }
    .download-app h1 {
      text-align: left !important; } }
  .download-app .download-text {
    max-width: 400px;
    padding-top: 60px; }
  .download-app .app-promo-img {
    margin-left: 50px; }
  .download-app h1 {
    text-align: right; }
    @media (max-width: 1024px) {
      .download-app h1 {
        text-align: center !important;
        font-size: 25px; } }
  @media (max-width: 1024px) {
    .download-app p {
      text-align: center; } }
  @media (max-width: 425px) {
    .download-app p {
      font-size: 15px; } }
  .download-app .green-text {
    color: #42e5aa;
    font-family: "Baufra Semi", sans-serif; }

.app_img-wrapper {
  position: relative;
  max-width: 677px; }
  .app_img-wrapper .app-bubbles {
    position: absolute;
    right: -32px;
    top: 21%; }
