.user-recover-password input[type="password"] {
  font-family: inherit; }

@media (max-width: 768px) {
  .recover-page-wrapper #order-address-selection-link {
    margin: 0 auto;
    text-align: center; } }

@media (max-width: 768px) {
  .recover-page-wrapper button {
    min-width: 300px; } }

@media (max-width: 768px) {
  .recover-page-wrapper input {
    margin: 20px auto 10px auto !important; } }

.recover-page-wrapper .inputs-wrapper.show-expired-background {
  background-image: url("../../../images/clock.svg");
  background-repeat: no-repeat;
  background-position: 90% 0;
  background-size: 180px; }
  @media (max-width: 768px) {
    .recover-page-wrapper .inputs-wrapper.show-expired-background {
      background-image: none !important; } }

.recover-page-wrapper .first-screen-inputs.recover .cd-wrapper {
  margin: 0 5px;
  display: inline; }

.recover-page-wrapper .first-screen-inputs.recover span {
  font-size: 14px; }
  .recover-page-wrapper .first-screen-inputs.recover span b {
    font-family: Baufra Medium, sans-serif; }

.recover-page-wrapper .first-screen-inputs.recover h1 {
  margin-top: 0; }

.recover-page-wrapper .first-screen-inputs.recover .inputs-wrapper {
  max-width: 700px;
  width: 700px; }
  @media (max-width: 1024px) {
    .recover-page-wrapper .first-screen-inputs.recover .inputs-wrapper {
      max-width: 550px; } }
  @media (max-width: 768px) {
    .recover-page-wrapper .first-screen-inputs.recover .inputs-wrapper {
      max-width: 400px;
      margin: 0 auto !important; } }
  .recover-page-wrapper .first-screen-inputs.recover .inputs-wrapper.home-banner {
    max-width: 320px; }
  .recover-page-wrapper .first-screen-inputs.recover .inputs-wrapper .page-loader {
    width: 700px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 50px; }
    @media (max-width: 1024px) {
      .recover-page-wrapper .first-screen-inputs.recover .inputs-wrapper .page-loader {
        max-width: 550px; } }
    @media (max-width: 768px) {
      .recover-page-wrapper .first-screen-inputs.recover .inputs-wrapper .page-loader {
        margin: 20px auto 40px auto !important;
        width: 100%; } }

#recover-button-re-send-email:disabled {
  background-color: #e9e9e4;
  box-shadow: none;
  border: none; }
  #recover-button-re-send-email:disabled:active {
    border: none;
    box-shadow: none; }
  #recover-button-re-send-email:disabled div {
    color: #ffffff; }
