* {
  box-sizing: border-box; }

html {
  font-size: 62.5%;
  scroll-behavior: smooth; }

body {
  font-family: 'Baufra', sans-serif;
  font-size: 16px;
  font-size: 1.6rem;
  -webkit-font-smoothing: antialiased; }
  body.menu-opened {
    overflow: hidden; }

html, body {
  -webkit-overflow-scrolling: touch !important;
  height: 100% !important; }

a {
  text-decoration: none; }

#root {
  margin: 0 auto;
  overflow: hidden; }
  @media (max-width: 1200px) {
    #root {
      max-width: 1200px; } }
  @media (max-width: 1024px) {
    #root {
      max-width: 1024px; } }
  @media (max-width: 768px) {
    #root {
      max-width: 768px; } }
  @media (max-width: 425px) {
    #root {
      max-width: 425px;
      width: 100%; } }
  @media (max-width: 320px) {
    #root {
      max-width: 320px; } }
