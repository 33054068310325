.accodrion-block {
  width: 100%; }

.accordion__title {
  background-color: transparent;
  outline: none; }
  .accordion__title h3 {
    max-width: 500px;
    margin-top: 0;
    margin-right: 25px;
    color: #000;
    font-family: Baufra, sans-serif; }
  .accordion__title:hover {
    background: transparent; }

.accordion__item {
  width: 100%;
  padding-left: 70px; }

.acc-title-wrapper {
  position: relative; }

.accordion__item + .accordion__item {
  border-top: none; }

.accordion__body p {
  font-family: Baufra, sans-serif !important; }
