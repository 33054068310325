.terms_block-wrapper .terms_block {
  max-width: 1200px;
  padding: 0 20px;
  width: 100%;
  margin: 0 auto; }

.terms_block-wrapper .terms_block {
  position: relative;
  z-index: 2;
  padding: 80px 100px;
  margin-top: -300px;
  border-radius: 5px;
  background-color: #ffffff;
  box-shadow: 0 5px 42px 0 rgba(187, 201, 192, 0.3); }

@media (max-width: 425px) {
  .terms-page-wrapper .hero-image {
    min-height: 0;
    max-height: 100px; } }

@media (max-width: 425px) {
  .terms-page-wrapper .hero-image.active {
    max-height: none; } }

.terms_block-wrapper {
  padding: 1em; }
  .terms_block-wrapper .terms_block {
    padding: 34px 24px; }
    @media (max-width: 425px) {
      .terms_block-wrapper .terms_block {
        margin-top: 50px; } }
    .terms_block-wrapper .terms_block > p {
      padding: 8px 0; }
    .terms_block-wrapper .terms_block h1 {
      margin: 36px 0;
      margin-top: 0;
      font-size: 25px; }
    .terms_block-wrapper .terms_block h2 {
      font-size: 18px; }
    .terms_block-wrapper .terms_block p {
      padding: 4px 0;
      font-size: 15px;
      line-height: 25px; }
