.faq-mobile-tabs__block {
  display: none; }
  @media (max-width: 768px) {
    .faq-mobile-tabs__block {
      display: block; } }
  .faq-mobile-tabs__block .accordion__item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-top: 1px solid #e5ecf3;
    padding-left: 0 !important; }
    .faq-mobile-tabs__block .accordion__item > .accordion__title {
      padding-left: 0; }
      .faq-mobile-tabs__block .accordion__item > .accordion__title h3 {
        margin-bottom: 0; }
        @media (max-width: 425px) {
          .faq-mobile-tabs__block .accordion__item > .accordion__title h3 {
            max-width: 250px; } }
        @media (max-width: 320px) {
          .faq-mobile-tabs__block .accordion__item > .accordion__title h3 {
            max-width: 160px; } }
      @media (max-width: 425px) {
        .faq-mobile-tabs__block .accordion__item > .accordion__title {
          padding: 25px; } }
    .faq-mobile-tabs__block .accordion__item > .accordion__body {
      width: 100%; }
