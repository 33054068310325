.faq-block {
  max-width: 1200px;
  padding: 0 20px;
  width: 100%;
  margin: 0 auto; }

.faq-block {
  position: relative;
  z-index: 2;
  padding: 80px 100px;
  margin-top: -300px;
  border-radius: 5px;
  background-color: #ffffff;
  box-shadow: 0 5px 42px 0 rgba(187, 201, 192, 0.3); }

.faq-block h1 {
  color: #000;
  font-family: "Baufra Semi", sans-serif;
  font-size: 4rem; }

.faq-block p {
  color: #000;
  font-family: "Baufra Medium", sans-serif;
  font-size: 2rem;
  line-height: 1.4;
  text-align: right; }

.faq-block__wrapper {
  padding: 16px; }

.faq-block {
  position: relative;
  padding-bottom: 190px;
  min-height: 1100px; }
  .faq-block h1 {
    font-family: Baufra, sans-serif; }
    @media (max-width: 425px) {
      .faq-block h1 {
        font-size: 25px; } }
  .faq-block p {
    text-align: left;
    font-size: 16px;
    line-height: 25px; }
    .faq-block p > br {
      display: none; }
      @media (max-width: 768px) {
        .faq-block p > br {
          display: block; } }
  .faq-block > img {
    position: absolute;
    bottom: 0;
    z-index: -1; }
    @media (max-width: 768px) {
      .faq-block > img {
        display: none; } }
  @media (max-width: 768px) {
    .faq-block {
      min-height: 100%;
      padding-bottom: 30px;
      padding-top: 0; } }
  @media (max-width: 425px) {
    .faq-block {
      padding: 0;
      margin-top: 50px; } }
  .faq-block .faq-header_wrapper {
    padding: 36px 25px;
    padding-left: 0; }
    @media (max-width: 425px) {
      .faq-block .faq-header_wrapper {
        padding-left: 25px; } }

.accordion__arrow::before, [aria-expanded='true'] .accordion__arrow::before {
  transform: rotate(45deg); }

[aria-selected='true'] .accordion__arrow::before {
  transform: rotate(-45deg); }

.accordion__arrow::after, [aria-expanded='true'] .accordion__arrow::after {
  right: 4px;
  transform: rotate(-45deg); }

[aria-selected='true'] .accordion__arrow::after {
  transform: rotate(45deg); }

.accordion__title--animated[aria-selected='true']:hover .accordion__arrow {
  animation-name: move-up;
  animation-duration: 1.5s; }
