.explore-item, .explore-block {
  max-width: 1200px;
  padding: 0 20px;
  width: 100%;
  margin: 0 auto; }

.explore-item {
  border-radius: 20px;
  background-color: #fff;
  box-shadow: 0 5px 42px 0 rgba(187, 201, 192, 0.3); }

.explore-item .button_wrapper a {
  display: inline-block;
  border-radius: 30px;
  background-color: #fff;
  box-shadow: 0 4px 25px 0 rgba(83, 170, 112, 0.2);
  font-size: 1.5rem;
  font-family: Baufra Semi, sans-serif;
  text-align: center;
  padding: 20px 35px;
  color: #42e5aa;
  cursor: pointer;
  outline: none;
  box-shadow: 0 4px 12px 0 rgba(53, 93, 255, 0.2);
  border: 0; }

.explore-block {
  position: relative;
  z-index: 2;
  padding: 80px 100px;
  margin-top: -300px;
  border-radius: 5px;
  background-color: #ffffff;
  box-shadow: 0 5px 42px 0 rgba(187, 201, 192, 0.3); }

@media (max-width: 425px) {
  .explore-page-wrapper .hero-image {
    min-height: 0 !important;
    max-height: 100px !important; }
    .explore-page-wrapper .active.hero-image {
      min-height: 100% !important;
      padding-top: 50px !important; } }

.explore-page-wrapper .hero-image {
  overflow: hidden; }
  @media (max-width: 425px) {
    .explore-page-wrapper .hero-image {
      overflow: visible; } }

.explore-col {
  flex: 1; }

.explore-block {
  box-shadow: none;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  background: none;
  padding: 80px 30px;
  padding-top: 0; }
  @media (max-width: 425px) {
    .explore-block {
      flex-direction: column;
      margin-top: 50px;
      padding: 16px; } }

.explore-item_wrapper {
  padding: 30px 32px; }
  .explore-item_wrapper.img {
    padding: 20px; }

.explore-item__title {
  font-family: Baufra Semi, sans-serif;
  margin: 8px 0;
  margin-top: 32px; }
  .explore-item__title:first-child {
    margin-top: 0; }

.explore-item {
  border-radius: 5px;
  max-width: 345px;
  min-width: 345px;
  flex: 1;
  padding: 5px;
  margin-bottom: 28px; }
  .explore-item > p {
    font-family: Baufra Semi, sans-serif;
    padding: 32px; }
    .explore-item > p > ol {
      padding-left: 25px; }
  @media (max-width: 425px) {
    .explore-item {
      width: 100%;
      max-width: 100%;
      min-width: 100%; } }
  .explore-item img {
    width: 100%; }
  .explore-item .button_wrapper {
    text-align: center; }
    .explore-item .button_wrapper a {
      background-color: #42e5aa;
      border: none;
      color: #fff; }
