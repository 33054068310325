@media (max-width: 425px) {
  .confirm-page-wrapper .hero-image {
    min-height: 0 !important;
    max-height: 100px !important; }
    .confirm-page-wrapper .active.hero-image {
      min-height: 100% !important;
      padding-top: 50px !important; } }

.confirm-page-wrapper .confirm-main {
  padding: 0 20px;
  padding-top: 40px; }
  .confirm-page-wrapper .confirm-main h1 {
    text-align: center; }
  .confirm-page-wrapper .confirm-main p {
    text-align: center; }
