.contact-info {
  max-width: 1200px;
  padding: 0 20px;
  width: 100%;
  margin: 0 auto; }

.contact-info {
  position: relative;
  z-index: 2;
  padding: 80px 100px;
  margin-top: -300px;
  border-radius: 5px;
  background-color: #ffffff;
  box-shadow: 0 5px 42px 0 rgba(187, 201, 192, 0.3); }

.contact-info h1 {
  color: #000;
  font-family: "Baufra Semi", sans-serif;
  font-size: 4rem; }

.contact-info p {
  color: #000;
  font-family: "Baufra Medium", sans-serif;
  font-size: 2rem;
  line-height: 1.4;
  text-align: right; }

.contact_info__wrapper {
  padding: 16px; }

.contact-info {
  padding-bottom: 120px;
  display: flex;
  justify-content: space-between; }
  @media (max-width: 768px) {
    .contact-info {
      flex-wrap: wrap;
      padding: 24px; } }
  @media (max-width: 425px) {
    .contact-info {
      margin-top: 50px; } }
  .contact-info > div {
    width: 485px;
    max-width: 410px; }
  .contact-info .contact-man {
    position: absolute;
    bottom: 0;
    left: 322px; }
  .contact-info h1 {
    margin: 0 0 10px 0; }
    @media (max-width: 768px) {
      .contact-info h1 {
        font-size: 25px;
        font-family: Baufra Semi, sans-serif;
        line-height: 30px;
        margin-top: 36px; } }
  .contact-info p {
    text-align: left;
    font-family: Baufra Semi, sans-serif; }
    @media (max-width: 768px) {
      .contact-info p {
        font-size: 1em;
        line-height: 30px;
        margin-top: 36px; } }
  .contact-info .contact-sub {
    font-size: 2.4rem;
    font-family: "Baufra", sans-serif;
    margin-top: 0; }
    @media (max-width: 768px) {
      .contact-info .contact-sub {
        font-size: 15px;
        line-height: 25px; } }
  .contact-info a {
    color: #4e8af3; }
  .contact-info .call-container {
    background: url("../../images/phone-icon.svg") no-repeat 12px center;
    padding-left: 100px;
    line-height: 1.8; }
  .contact-info .email-container {
    background: url("../../images/email-icon.svg") no-repeat left center;
    padding-left: 100px;
    line-height: 1.8;
    margin-bottom: 50px; }
  @media (max-width: 425px) {
    .contact-info .schedule-descr {
      margin-top: 16px; } }
