.rwt__tab {
  font-size: 20px;
  letter-spacing: 0;
  line-height: 25px;
  color: #d9d9d9;
  padding: 25px 76px 25px 0;
  min-width: 220px;
  text-align: left;
  font-family: Baufra Medium, sans-serif; }
  .rwt__tab[aria-selected="true"] {
    color: #42e5aa;
    background-color: #fff !important; }
  .rwt__tab:hover {
    background-color: #fff !important; }

.tabs-inner {
  width: 100%; }

.rwt__tablist[aria-orientation="vertical"] .rwt__tab[aria-selected="true"]:after {
  display: none; }

.tabs-section {
  padding-top: 90px; }

@media (max-width: 768px) {
  .faq-tabs__block {
    display: none !important; } }
