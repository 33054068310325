.button_basic {
  display: inline-block;
  border-radius: 30px;
  background-color: #fff;
  box-shadow: 0 4px 25px 0 rgba(83, 170, 112, 0.2);
  font-size: 1.5rem;
  font-family: Baufra Semi, sans-serif;
  text-align: center;
  padding: 20px 35px;
  color: #42e5aa;
  cursor: pointer;
  outline: none;
  box-shadow: 0 4px 12px 0 rgba(53, 93, 255, 0.2);
  border: 0; }

.button_basic {
  font-family: Baufra Semi, sans-serif;
  color: #4a90e2;
  padding: 18px 35px;
  max-height: 55px;
  box-shadow: 0 2px 4px 0 rgba(52, 75, 73, 0.5);
  border: 1px solid #e9e9e4;
  min-width: 177px;
  transition: box-shadow 0.1s; }
  .checked.button_basic {
    transition: background-image 0.3s;
    background-image: linear-gradient(-180deg, #12dce7 0%, #72ee6d 100%);
    box-shadow: 0 4px 12px 0 rgba(0, 244, 81, 0.4);
    font-size: 15px;
    color: #ffffff;
    border: rgba(187, 201, 192, 0);
    letter-spacing: 0;
    text-align: center; }
  .button_basic:hover {
    box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.2);
    transition: box-shadow 0.1s; }
  .button_basic:active {
    border: #bbc9c0;
    box-shadow: inset 0 1px 12px 0 rgba(0, 0, 0, 0.4); }
  @media (max-width: 768px) {
    .button_basic {
      max-width: 140px;
      margin: 0 auto;
      padding: 15px 30px;
      text-align: center;
      max-width: 160px;
      min-width: 130px; } }
  @media (max-width: 425px) {
    .button_basic {
      max-width: 185px; } }

.button_basic {
  max-height: 80px;
  font-size: 15px;
  padding: 0 !important;
  min-height: 48px; }
  .button_basic div.title {
    padding: 0px 25px !important; }

.button_basic.button_with-arrow div {
  margin-right: 16px; }

.button_basic.button-green.checked {
  box-shadow: 0 2px 4px 0 rgba(52, 75, 73, 0.5);
  border: solid 1px #e9e9e4;
  background-color: #42e5aa;
  background-image: none; }
  .button_basic.button-green.checked:active {
    box-shadow: 0 5px 6px 0 rgba(28, 66, 75, 0.5) !important;
    background-color: #42dda2; }
  .button_basic.button-green.checked:hover {
    box-shadow: 0 3px 4px 0 rgba(36, 73, 75, 0.5); }
  .button_basic.button-green.checked:disabled {
    background-color: rgba(63, 208, 152, 0.46); }
  .button_basic.button-green.checked div {
    color: #ffffff; }

.button_basic.button-blue.checked {
  box-shadow: 0 2px 4px 0 rgba(52, 75, 73, 0.5);
  border: solid 1px #e9e9e4;
  background-color: #4e8af3;
  background-image: none; }
  .button_basic.button-blue.checked:active {
    box-shadow: 0 5px 6px 0 rgba(52, 75, 73, 0.5) !important;
    background-color: #4d7fe7; }
  .button_basic.button-blue.checked:hover {
    box-shadow: 0 3px 4px 0 rgba(52, 75, 73, 0.5); }
  .button_basic.button-blue.checked:disabled {
    background-color: rgba(78, 138, 243, 0.64); }
  .button_basic.button-blue.checked div {
    color: #ffffff; }
