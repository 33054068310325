.about-dobbi_content {
  max-width: 1200px;
  padding: 0 20px;
  width: 100%;
  margin: 0 auto; }

.about-dobbi_content {
  border-radius: 20px;
  background-color: #fff;
  box-shadow: 0 5px 42px 0 rgba(187, 201, 192, 0.3); }

.about-dobbi_content h1 {
  color: #000;
  font-family: "Baufra Semi", sans-serif;
  font-size: 4rem; }

.about-dobbi_content p {
  color: #000;
  font-family: "Baufra Medium", sans-serif;
  font-size: 2rem;
  line-height: 1.4;
  text-align: right; }

.about-dobbi > img {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%); }

.about-logos {
  display: flex;
  justify-content: center;
  align-items: center; }

.about-dobbi {
  position: relative;
  z-index: 1; }
  @media (max-width: 425px) {
    .about-dobbi {
      padding: 15px; } }

.about-dobbi_content {
  position: relative;
  z-index: 2;
  padding: 25px;
  margin: 10px auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-size: cover; }
  .about-dobbi_content .feature-image {
    height: 454px; }
  @media (max-width: 768px) {
    .about-dobbi_content {
      flex-wrap: wrap;
      justify-content: center;
      margin: 10px auto;
      border-radius: 0;
      width: 75%; }
      .about-dobbi_content .feature-image {
        max-width: 100%;
        width: auto;
        height: auto; } }
  @media (max-width: 425px) {
    .about-dobbi_content {
      width: 100%;
      padding: 10px; }
      .about-dobbi_content .feature-image {
        display: none; } }
  @media (max-width: 425px) {
    .about-dobbi_content h1 {
      text-align: center;
      margin-top: 0; } }
  .about-dobbi_content p {
    text-align: left;
    font-size: 1.8rem;
    position: relative; }
    .about-dobbi_content p b {
      font-family: "Baufra Semi", sans-serif; }
    .about-dobbi_content p:last-child {
      color: #42e5aa; }
    .about-dobbi_content p:before {
      content: "";
      position: absolute;
      height: 18px;
      width: 18px;
      top: 50%;
      transform: translateY(-50%);
      left: -40px;
      background: url("../../images/about-imgs/bullet.svg"); }
  .about-dobbi_content .about-text {
    padding: 60px 40px;
    max-width: 520px; }
    @media (max-width: 320px) {
      .about-dobbi_content .about-text {
        padding: 60px 40px; } }

.about-logos {
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 40px; }
  @media (max-width: 768px) {
    .about-logos .about-item {
      flex-basis: 50%;
      margin-top: 40px; } }

.about-logos-wrapper {
  text-align: center; }

.slick-arrow {
  z-index: 2;
  height: 88px;
  width: 88px;
  background: url("../../images/about-imgs/slider-arrow.png");
  background-size: contain; }
  .slick-arrow::before {
    display: none; }

.slick-prev:hover, .slick-prev:focus {
  background: url("../../images/about-imgs/slider-arrow.png");
  background-size: contain; }

.slick-next:hover, .slick-next:focus, .slick-next {
  background: url("../../images/about-imgs/slider-arrow-next.png");
  background-size: contain; }

.slick-prev {
  left: -5px; }

.slick-next {
  right: -5px; }
