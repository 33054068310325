.app-links {
  position: relative;
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 40px; }
  .app-links a {
    display: inline-block;
    margin-left: 15px; }
