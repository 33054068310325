.pricelist-button, .check-area-inputs .check-form-submit {
  display: inline-block;
  border-radius: 30px;
  background-color: #fff;
  box-shadow: 0 4px 25px 0 rgba(83, 170, 112, 0.2);
  font-size: 1.5rem;
  font-family: Baufra Semi, sans-serif;
  text-align: center;
  padding: 20px 35px;
  color: #42e5aa;
  cursor: pointer;
  outline: none;
  box-shadow: 0 4px 12px 0 rgba(53, 93, 255, 0.2);
  border: 0; }

.home-page-wrapper .app-download-home h1 {
  color: #000;
  font-family: "Baufra Semi", sans-serif;
  font-size: 4rem; }

.home-page-wrapper .app-download-home p {
  color: #000;
  font-family: "Baufra Medium", sans-serif;
  font-size: 2rem;
  line-height: 1.4;
  text-align: right; }

.home-page-wrapper .mouse-icon {
  position: absolute;
  left: 50%;
  transform: translateX(-50%); }

.home-page-wrapper .mouse-icon img {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%); }

.chech-area-wrapper, .check-area-inner, .check-area-inputs {
  display: flex;
  justify-content: center;
  align-items: center; }

.check-area-inputs input {
  display: inline-block;
  border-radius: 17px;
  background-color: #fff;
  box-shadow: 0 4px 25px 0 rgba(83, 170, 112, 0.2);
  padding: 18px 35px;
  outline: none;
  border: 1px solid #979797;
  color: #000000; }

.home-page-wrapper .hero-image {
  min-height: 590px; }
  @media (max-width: 425px) {
    .home-page-wrapper .hero-image {
      min-height: 544px;
      background: none; }
      .home-page-wrapper .hero-image img {
        display: block;
        max-height: 554px; } }

.home-page-wrapper .app-download-home {
  position: absolute;
  bottom: 150px;
  border-radius: 10px;
  background-color: #ffffff;
  box-shadow: 0 5px 42px 0 rgba(187, 201, 192, 0.3); }
  @media (max-width: 768px) {
    .home-page-wrapper .app-download-home {
      left: 50%;
      transform: translateX(-50%);
      bottom: 50px; }
      .home-page-wrapper .app-download-home h1 {
        font-size: 2.5rem; }
      .home-page-wrapper .app-download-home p {
        font-size: 1.8rem; } }
  @media (max-width: 425px) {
    .home-page-wrapper .app-download-home {
      padding: 25px 20px;
      width: 100%;
      border-radius: 0;
      margin-bottom: -210px; }
      .home-page-wrapper .app-download-home h1 {
        font-size: 2.2rem; }
      .home-page-wrapper .app-download-home p {
        font-size: 1.5rem; } }
  .home-page-wrapper .app-download-home h1 {
    margin: 0; }
  .home-page-wrapper .app-download-home p {
    margin: 10px 0;
    text-align: left; }
  .home-page-wrapper .app-download-home .app-links {
    justify-content: flex-start; }
    .home-page-wrapper .app-download-home .app-links a {
      margin: 0 15px 0 0; }

.home-page-wrapper .mouse-icon {
  bottom: -40px;
  height: 80px;
  width: 80px;
  background-color: #fff;
  box-shadow: 0 3px 27px 0 rgba(187, 201, 192, 0.3);
  display: inline-block;
  border-radius: 50%;
  outline: 0;
  border: none; }
  @media (max-width: 768px) {
    .home-page-wrapper .mouse-icon {
      display: none; } }

.home-page-wrapper .download-app {
  flex-direction: row-reverse; }

.slick-dots {
  bottom: -40px; }

.mobile_header-link {
  display: none; }
  .mobile_header-link .mobile-header {
    margin: 0 !important;
    display: flex;
    flex-direction: row;
    justify-content: space-between; }
    .mobile_header-link .mobile-header span {
      font-family: Baufra Semi, sans-serif;
      font-size: 20px;
      line-height: 24px; }
    .mobile_header-link .mobile-header img {
      width: 25px; }
  .mobile_header-link .mobile-descr {
    margin: 0 !important;
    color: #42e5aa !important;
    font-family: Baufra Semi, sans-serif;
    font-size: 15px;
    line-height: 25px; }
  @media (max-width: 425px) {
    .mobile_header-link {
      display: block; } }

@media (max-width: 425px) {
  .download_heder-home {
    display: none; } }

@media (max-width: 425px) {
  .mobile_home-header > .desktop_header-link {
    display: none; } }

.app-download-home {
  max-width: 950px;
  display: flex;
  flex-direction: column;
  position: relative !important;
  bottom: 160px !important;
  margin: 0 auto; }
  @media (max-width: 425px) {
    .app-download-home {
      background: none !important;
      box-shadow: none !important;
      bottom: 295px !important; } }

@media (max-width: 425px) {
  .how-it-works {
    margin-top: 0; } }

.app-download-home__wrapper {
  padding: 45px 40px; }
  @media (max-width: 425px) {
    .app-download-home__wrapper {
      padding: 25px 20px;
      background: #fff;
      border-radius: 10px;
      box-shadow: 0 5px 42px 0 rgba(187, 201, 192, 0.3);
      max-width: 330px;
      max-height: 90px;
      margin: 0 auto; } }
  @media (max-width: 425px) {
    .app-download-home__wrapper {
      width: 100%;
      margin-bottom: 20px;
      max-width: 100%; } }

.app-download-home__wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  padding-bottom: 45px; }
  @media (max-width: 1200px) {
    .app-download-home__wrapper {
      flex-direction: column; } }
  .app-download-home__wrapper:after {
    position: absolute;
    content: "";
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: #e9e9e9; }
    @media (max-width: 425px) {
      .app-download-home__wrapper:after {
        display: none; } }

.close-button {
  border: none;
  position: absolute;
  font-size: 22px;
  top: 25px;
  right: 25px;
  cursor: pointer;
  background: #fff; }

.chech-area-wrapper {
  position: relative;
  max-height: 132px;
  height: 132px;
  background: linear-gradient(180deg, #12dce7 0%, #8c35ff 100%); }
  @media (max-width: 768px) {
    .chech-area-wrapper {
      padding: 40px 0;
      max-height: 120px;
      height: 100%;
      margin-top: 28px; } }
  @media (max-width: 425px) {
    .chech-area-wrapper {
      margin-top: 70px; } }

@media (max-width: 768px) {
  .check-area-inner {
    flex-direction: column; } }

.check-area-text {
  color: #fff;
  font-size: 25px;
  line-height: 30px;
  font-family: Baufra Semi, sans-serif; }
  @media (max-width: 768px) {
    .check-area-text {
      font-size: 18px;
      margin: 12px;
      margin-top: 0; } }

.pricelist-button {
  font-family: Baufra Semi, sans-serif;
  color: #4e8af3;
  padding: 18px 4px;
  max-height: 55px;
  box-shadow: none;
  border: 1px solid #dfdfe4;
  min-width: 180px !important;
  max-width: 180px !important;
  transition: box-shadow 0.1s; }
  .pricelist-button.checked {
    transition: background-image 0.3s;
    background-image: linear-gradient(-180deg, #12dce7 0%, #72ee6d 100%);
    box-shadow: 0 4px 12px 0 rgba(0, 244, 81, 0.4);
    font-size: 15px;
    color: #ffffff;
    border: rgba(187, 201, 192, 0);
    letter-spacing: 0;
    text-align: center; }
  .pricelist-button:hover {
    box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.2);
    transition: box-shadow 0.1s; }
  .pricelist-button:active {
    border: #bbc9c0;
    box-shadow: inset 0 1px 12px 0 rgba(0, 0, 0, 0.4); }
  @media (max-width: 768px) {
    .pricelist-button {
      max-width: 140px;
      margin: 0 auto;
      padding: 15px 25px;
      text-align: center;
      max-width: 130px;
      padding: 15px 35px;
      max-width: 100% !important; } }

.check-area-inputs {
  margin-left: 80px; }
  @media (max-width: 768px) {
    .check-area-inputs {
      margin-left: 0; } }
  .check-area-inputs input {
    max-width: 130px;
    box-shadow: none;
    width: 100%;
    padding: 15px 35px;
    border: 1px solid #dfdfe4; }
  .check-area-inputs .postal-input {
    overflow: hidden; }
    @media (max-width: 768px) {
      .check-area-inputs .postal-input {
        margin-right: 15px; } }
  .check-area-inputs .check-form-submit {
    margin-left: 30px;
    font-family: Baufra Semi, sans-serif;
    color: #4e8af3;
    padding: 18px 55px;
    max-height: 55px;
    box-shadow: none;
    border: 1px solid #dfdfe4;
    min-width: 177px;
    transition: box-shadow 0.1s; }
    .check-area-inputs .check-form-submit.checked {
      transition: background-image 0.3s;
      background-image: linear-gradient(-180deg, #12dce7 0%, #72ee6d 100%);
      box-shadow: 0 4px 12px 0 rgba(0, 244, 81, 0.4);
      font-size: 15px;
      color: #ffffff;
      border: rgba(187, 201, 192, 0);
      letter-spacing: 0;
      text-align: center; }
    .check-area-inputs .check-form-submit:hover {
      box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.2);
      transition: box-shadow 0.1s; }
    .check-area-inputs .check-form-submit:active {
      border: #bbc9c0;
      box-shadow: inset 0 1px 12px 0 rgba(0, 0, 0, 0.4); }
    @media (max-width: 768px) {
      .check-area-inputs .check-form-submit {
        max-width: 140px;
        margin: 0 auto;
        padding: 15px 30px;
        text-align: center;
        max-width: 130px;
        min-width: 130px; } }

.button-wrapper {
  text-align: center;
  margin: 90px 0; }
