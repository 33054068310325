.error-page-wrapper .error-block {
  padding: 50px; }

.error-page-wrapper .error-logo {
  width: 100%;
  padding: 50px 10px; }
  .error-page-wrapper .error-logo img {
    display: block;
    margin: 0 auto !important; }
    @media (max-width: 425px) {
      .error-page-wrapper .error-logo img {
        width: 150px; } }

@media (max-width: 768px) {
  .error-page-wrapper .error-text h1 {
    font-size: 18px; } }

.error-page-wrapper .error-text p {
  margin: 0 auto;
  padding: 10px 0;
  text-align: center !important; }
