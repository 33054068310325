$smartbanner-close-margin: 5px;
$smartbanner-close-size: 17px;
$smartbanner-icon-margin: 12px;
$smartbanner-icon-size: 57px;
$smartbanner-button-size: 110px;

.smartbanner-show {
  &.smartbanner-margin-top {
    //margin-top: 80px;
  }

  &.smartbanner-margin-bottom {
    margin-bottom: 80px;
  }

  .smartbanner {
    display: block;
  }

  .order-page-wrapper {
    //margin-top: 84px;
  }
}

/** Default **/
.smartbanner {
  left: 0;
  display: none;
  width: 100%;
  height: 84px;
  line-height: 80px;
  font-family: 'Helvetica Neue', sans-serif;
  background: transparent;
  z-index: 50;
  -webkit-font-smoothing: antialiased;
  overflow: hidden;
  -webkit-text-size-adjust: none;
}

.smartbanner-top {
  //position: absolute;
  //top: 0;
}

.smartbanner-bottom {
  position: fixed;
  bottom: 0;
}

.smartbanner-container {
  max-width: 1164px;
  padding: 0 10px 0 5px;
  width: 100%;
  margin: 0 auto;
}

.smartbanner-close {
  display: inline-block;
  vertical-align: middle;
  margin: 0 $smartbanner-close-margin 0 0;
  font-family: 'ArialRoundedMTBold', Arial;
  font-size: 20px;
  text-align: center;
  color: #888;
  text-decoration: none;
  border: 0;
  border-radius: 14px;
  padding: 0 0 1px;
  background-color: transparent;
  -webkit-font-smoothing: subpixel-antialiased;

  &:active,
  &:hover {
    color: #aaa;
  }
}

.smartbanner-icon {
  display: inline-block;
  vertical-align: middle;
  width: $smartbanner-icon-size;
  height: $smartbanner-icon-size;
  margin-right: $smartbanner-icon-margin;
  border-radius: 10px;
}

.smartbanner-info {
  white-space: normal;
  display: inline-block;
  vertical-align: middle;
  width: calc(99% - #{$smartbanner-close-margin} - #{$smartbanner-close-size} - #{$smartbanner-icon-margin} - #{$smartbanner-icon-size} - #{$smartbanner-button-size});
  font-size: 12px;
  line-height: 1.2em;
  font-weight: normal;
}

.smartbanner-wrapper {
  max-width: $smartbanner-button-size;
  display: inline-block;
  text-align: right;
  width: 100%;
}

.smartbanner-title {
  font-size: 13px;
  line-height: 18px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.smartbanner-description {
  max-height: 40px;
  overflow: hidden;
}

.smartbanner-author {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;

  &:empty {
    + .smartbanner-description {
      max-height: 50px;
    }
  }
}

.smartbanner-button {
  margin: auto 0;
  height: 24px;
  font-size: 12px;
  line-height: 24px;
  text-align: center;
  font-weight: bold;
  color: #6a6a6a;
  text-transform: uppercase;
  text-decoration: none;
  display: inline-block;
  text-shadow: 0 1px 0 rgba(255, 255, 255, 0.8);

  &:active,
  &:hover {
    color: #aaa;
  }
}

/** iOS **/
.smartbanner-ios {
  background: #e9e9e4;
  //box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
  line-height: 82px;

  .smartbanner-container {
    padding: 0 1px 0 5px;
  }

  .smartbanner-close {
    border: 0;
    width: 18px;
    height: 18px;
    line-height: 18px;
    font-family: Arial;
    color: #383838;
    text-shadow: 0 1px 0 #fff;
    -webkit-font-smoothing: none;
    font-size: 19px;

    &:active,
    &:hover {
      color: #888;
    }
  }

  .smartbanner-icon {
    background-size: cover;
  }

  .smartbanner-info {
    color: #383838;
    //text-shadow: 0 1px 0 rgba(255, 255, 255, 0.8);
    font-weight: normal;
  }

  .smartbanner-title {
    color: #383838;
    font-weight: 500;
  }

  .smartbanner-button {
    padding: 0 10px;
    min-width: 10%;
    font-weight: 400;
    color: #4e8af3;

    &:active,
    &:hover {
      background: #f2f2f2;
    }
  }

  .smartbanner-wrapper {
    a {
      top: 18px;
      position: relative;
      font-weight: bold;
    }
  }
}

/** Android **/
.smartbanner-android {
  line-height: 82px;
  background: #4e8af3;

  .smartbanner-close {
    border: 0;
    max-width: $smartbanner-close-size;
    width: 100%;
    height: $smartbanner-close-size;
    line-height: $smartbanner-close-size;
    margin-right: 7px;
    color: #ffffff;
    background: #1c1e21;
    text-shadow: 0 1px 1px #000;
    text-decoration: none;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.8) inset, 0 1px 1px rgba(255, 255, 255, 0.3);
    cursor: pointer;
    position: relative;
    top: -19px;

    &:active,
    &:hover {
      color: #eee;
    }
  }

  .smartbanner-icon {
    background-color: transparent;
    box-shadow: none;
  }

  .smartbanner-info {
    color: #fff;
  }

  .smartbanner-title {
    color: #fff;
    font-weight: bold;
  }

  .smartbanner-button {
    min-width: 12%;
    color: #ffffff;
    font-weight: bold;
    padding: 0;
    background: none;
    border-radius: 0;

    &:active,
    &:hover {
      background: none;
    }
  }

  .smartbanner-button-text {
    text-align: center;
    display: block;
    padding: 0 7px;
    background: #e58b21;
    text-transform: none;
    text-shadow: none;
    box-shadow: none;

    &:active,
    &:hover {
      background: #d68021;
    }
  }

  .smartbanner-wrapper {
    a {
      top: 10px;
      position: relative;
    }
  }
}

/** Windows / Kindle **/
.smartbanner-windows,
.smartbanner-kindle {
  background: #f4f4f4;
  background: linear-gradient(to bottom, #f4f4f4, #cdcdcd); // sass-lint:disable-block no-duplicate-properties
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.5);
  line-height: 80px;

  .smartbanner-close {
    border: 0;
    width: 18px;
    height: 18px;
    line-height: 18px;
    color: #888;
    text-shadow: 0 1px 0 #fff;

    &:active,
    &:hover {
      color: #aaa;
    }
  }

  .smartbanner-icon {
    background: rgba(0, 0, 0, 0.6);
    background-size: cover;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
  }

  .smartbanner-info {
    color: #6a6a6a;
    text-shadow: 0 1px 0 rgba(255, 255, 255, 0.8);
  }

  .smartbanner-title,
  .smartbanner-title {
    color: #4d4d4d;
    font-weight: bold;
  }

  .smartbanner-button {
    padding: 0 10px;
    min-width: 10%;
    color: #6a6a6a;
    background: #efefef;
    background: linear-gradient(to bottom, #efefef, #dcdcdc); // sass-lint:disable-block no-duplicate-properties
    border-radius: 3px;
    box-shadow: inset 0 0 0 1px #bfbfbf,
    0 1px 0 rgba(255, 255, 255, 0.6),
    0 2px 0 rgba(255, 255, 255, 0.7) inset;

    &:active,
    &:hover {
      background: #dcdcdc;
      background: linear-gradient(to bottom, #dcdcdc, #efefef); // sass-lint:disable-block no-duplicate-properties
    }
  }
}