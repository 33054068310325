.footer-wrapper footer {
  max-width: 1200px;
  padding: 0 20px;
  width: 100%;
  margin: 0 auto; }

.footer-wrapper {
  position: relative;
  background: linear-gradient(180deg, #3ce3bb 0%, #72ee6d 100%);
  min-height: 300px;
  margin-top: 269px; }
  @media (max-width: 425px) {
    .footer-wrapper {
      margin-top: 320px; } }
  .footer-wrapper .order-button {
    display: none !important; }
  .footer-wrapper .footer-curves {
    width: 100%;
    height: 219px;
    background: url("../../images/footer-curves.svg") no-repeat right center;
    background-size: cover;
    position: absolute;
    bottom: calc(100% - 1px);
    right: 0; }
    @media (max-width: 425px) {
      .footer-wrapper .footer-curves {
        background: url("../../images/mobile-footer.svg") no-repeat center;
        background-size: cover;
        height: 270px; } }
    .footer-wrapper .footer-curves .bubbles {
      position: absolute;
      background: url("../../images/bubbles.svg") no-repeat;
      width: 324px;
      height: 118px;
      right: 290px;
      top: 40px; }
      @media (max-width: 425px) {
        .footer-wrapper .footer-curves .bubbles {
          background: none; } }
  .footer-wrapper footer {
    padding: 0 20px 50px 20px; }
    @media (max-width: 425px) {
      .footer-wrapper footer {
        padding-bottom: 40px; } }
  .footer-wrapper .logo-container {
    position: relative;
    border-bottom: 2px solid rgba(255, 255, 255, 0.2);
    display: flex;
    justify-content: space-between;
    padding-right: 60px; }
    @media (max-width: 425px) {
      .footer-wrapper .logo-container {
        justify-content: center;
        padding: 0; } }
    @media (max-width: 425px) {
      .footer-wrapper .logo-container > .logo-container {
        border-bottom: none; } }
    .footer-wrapper .logo-container .dobbi-logo {
      margin-bottom: 40px; }
    .footer-wrapper .logo-container .dobbi-van {
      position: absolute;
      bottom: 0;
      right: 150px; }
      @media (max-width: 768px) {
        .footer-wrapper .logo-container .dobbi-van {
          right: 50px; } }
      @media (max-width: 425px) {
        .footer-wrapper .logo-container .dobbi-van {
          display: none; } }
  .footer-wrapper .footer-text {
    font-size: 1.5rem;
    color: #fff;
    font-family: Baufra Semi, sans-serif;
    line-height: 2;
    display: flex;
    justify-content: space-between; }
    @media (max-width: 768px) {
      .footer-wrapper .footer-text {
        flex-wrap: wrap; } }
    @media (max-width: 425px) {
      .footer-wrapper .footer-text {
        justify-content: center;
        align-items: center;
        align-content: center; }
        .footer-wrapper .footer-text .footer-block {
          border-bottom: 2px solid rgba(255, 255, 255, 0.2);
          padding-bottom: 1em; } }
    .footer-wrapper .footer-text .footer-desc {
      line-height: 1.6; }
    .footer-wrapper .footer-text > div {
      max-width: 220px;
      width: 220px;
      margin: 1em 0; }
      .footer-wrapper .footer-text > div p {
        margin-top: 0.3em; }
    .footer-wrapper .footer-text nav a {
      display: inline-block;
      width: 100%;
      color: #fff; }

.mobile__footer {
  display: none;
  width: 100% !important;
  max-width: 100% !important; }
  .mobile__footer .accordion__item {
    padding-left: 0;
    border-top: 2px solid rgba(255, 255, 255, 0.2) !important; }
    .mobile__footer .accordion__item:last-child {
      border-bottom: 2px solid rgba(255, 255, 255, 0.2); }
    .mobile__footer .accordion__item .accordion__title {
      position: relative; }
    .mobile__footer .accordion__item .accordion__title,
    .mobile__footer .accordion__item h3 {
      background: none;
      font-family: Baufra Semi, sans-serif;
      color: #fff;
      margin-bottom: 0; }
    .mobile__footer .accordion__item .accordion__body p {
      font-family: Baufra Semi, sans-serif !important;
      text-align: center;
      line-height: 25px; }
  @media (max-width: 425px) {
    .mobile__footer {
      display: block; } }

@media (max-width: 425px) {
  .footer-block_numbers {
    display: none; } }

@media (max-width: 425px) {
  .footer-block_numbers,
  .footer-payment {
    border-bottom: none !important;
    padding-bottom: 0 !important; } }

@media (max-width: 425px) {
  .desktop__foter {
    display: none; } }

@media (max-width: 425px) {
  .footer-block_numbers,
  .footer-desc {
    text-align: center; } }

@media (max-width: 768px) {
  .footer-block__nav {
    display: none; } }

.footer-socials {
  margin-top: 26px; }
  .footer-socials a:not(first-child) {
    margin-right: 25px; }
  @media (max-width: 768px) {
    .footer-socials a:last-child {
      margin-right: 0; } }
