.hero-image .hero-inner {
  max-width: 1200px;
  padding: 0 20px;
  width: 100%;
  margin: 0 auto; }

.language-switcher {
  display: inline-block;
  border-radius: 30px;
  background-color: #fff;
  box-shadow: 0 4px 25px 0 rgba(83, 170, 112, 0.2);
  font-size: 1.5rem;
  font-family: Baufra Semi, sans-serif;
  text-align: center;
  padding: 20px 35px;
  color: #42e5aa;
  cursor: pointer;
  outline: none;
  box-shadow: 0 4px 12px 0 rgba(53, 93, 255, 0.2);
  border: 0; }

.hero-image .header-text {
  position: absolute;
  left: 50%;
  transform: translateX(-50%); }

.top-menu nav .order-button, .hero-image.active .mobile_menu nav .order-button, .lng-switcher, .lng-switcher .lang-item, .switcher-wrapper {
  display: flex;
  justify-content: center;
  align-items: center; }

@media (max-width: 425px) {
  .hero-image {
    min-height: 0 !important;
    max-height: 100px !important; }
    .active.hero-image {
      min-height: 100% !important;
      padding-top: 50px !important; } }

.hero-image {
  position: relative;
  width: 100%;
  padding: 50px 20px 0 20px;
  min-height: 508px; }
  @media (max-width: 768px) {
    .hero-image {
      padding: 0; } }
  @media (max-width: 425px) {
    .hero-image {
      background: linear-gradient(180deg, #3ce3bb 0%, #72ee6d 100%); } }
  .hero-image .hero-inner {
    position: relative;
    z-index: 1;
    display: flex;
    justify-content: space-between;
    align-content: center; }
    @media (max-width: 768px) {
      .hero-image .hero-inner {
        background-color: #fff;
        padding: 25px; }
        .hero-image .hero-inner .logo-container img {
          display: none; }
        .hero-image .hero-inner .logo-container {
          width: 100px;
          height: 50px;
          background: url("../../images/logo-green.svg") no-repeat;
          background-size: contain; } }
  .hero-image .top-menu {
    margin-left: auto; }
    @media (max-width: 768px) {
      .hero-image .top-menu {
        display: none; } }
  .hero-image .shadow-container {
    height: 254px;
    width: 100%;
    opacity: 0.6;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0) 100%);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0; }
    @media (max-width: 768px) {
      .hero-image .shadow-container {
        display: none; } }
  .hero-image .header-hero-img {
    position: absolute;
    top: 0;
    right: 0;
    z-index: -1; }
    @media (max-width: 768px) {
      .hero-image .header-hero-img {
        left: -300px; } }
    @media (max-width: 425px) {
      .hero-image .header-hero-img {
        display: none; } }
  .hero-image.white-menu .shadow-container {
    display: none; }
  .hero-image.white-menu .header-hero-img {
    display: none; }
  .hero-image.white-menu .top-menu {
    background-color: #ffffff;
    box-shadow: 0 5px 42px 0 rgba(187, 201, 192, 0.3); }
  .hero-image .hero-img-wrapper {
    width: 100%;
    height: 592px;
    max-height: 592px;
    position: absolute;
    top: 0;
    right: 0;
    background: url("../../images/home/home-banner.png") no-repeat center;
    background-size: cover;
    left: 50%;
    transform: translateX(-50%); }
    @media (max-width: 768px) {
      .hero-image .hero-img-wrapper {
        top: 30px;
        display: none; } }
    .hero-image .hero-img-wrapper.error-page {
      background: #fff; }
      .hero-image .hero-img-wrapper.error-page .header-text {
        display: none; }
  .hero-image .mobile-sides {
    display: none;
    position: absolute;
    width: 100%;
    bottom: 0; }
    @media (max-width: 425px) {
      .hero-image .mobile-sides {
        display: block; } }
  .hero-image .header-text {
    display: none;
    align-items: flex-end;
    justify-content: center;
    position: absolute;
    bottom: 0;
    width: 100%; }
    @media (max-width: 768px) {
      .hero-image .header-text {
        flex-direction: column;
        align-items: center; } }
    .hero-image .header-text .header-text-descr {
      margin-bottom: 70px;
      max-width: 530px;
      margin-right: 80px; }
      @media (max-width: 768px) {
        .hero-image .header-text .header-text-descr {
          max-width: 280px;
          text-align: center;
          margin-bottom: 0;
          margin-right: 0; } }
      .hero-image .header-text .header-text-descr .white-titles {
        background: #ffffff;
        display: inline-block;
        text-transform: uppercase;
        color: #42e5aa;
        font-family: Baufra;
        font-size: 41px;
        line-height: 49px;
        margin: 2px 0; }
        @media (max-width: 768px) {
          .hero-image .header-text .header-text-descr .white-titles {
            font-size: 22px;
            line-height: 32px; } }
      .hero-image .header-text .header-text-descr .white-descr {
        color: #ffffff;
        max-width: 460px;
        font-size: 22px;
        font-family: Baufra Semi, sans-serif; }
        @media (max-width: 768px) {
          .hero-image .header-text .header-text-descr .white-descr {
            max-width: 280px;
            font-size: 15px;
            line-height: 18px;
            text-align: center; } }
    .hero-image .header-text .header-text-phone {
      display: none; }
      .hero-image .header-text .header-text-phone img {
        width: 100%; }
      @media (max-width: 768px) {
        .hero-image .header-text .header-text-phone {
          width: 190px; } }

.top-menu {
  display: flex;
  align-items: center; }
  .top-menu .hidden {
    display: none; }
  .top-menu nav {
    align-items: center; }
    .top-menu nav .order-button {
      margin-right: 25px;
      margin-left: 50px;
      color: #42e5aa;
      border-radius: 30px;
      font-size: 12px;
      padding: 6px 16px;
      background-color: #ffffff; }
      @media (max-width: 768px) {
        .top-menu nav .order-button.desktop {
          display: flex; } }
      .top-menu nav .order-button.mobile {
        display: none; }
        @media (max-width: 768px) {
          .top-menu nav .order-button.mobile {
            display: block; } }
      .top-menu nav .order-button:hover, .top-menu nav .order-button.active {
        border-bottom: none; }
    .top-menu nav div {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: center;
      padding-right: 30px; }
    .top-menu nav a {
      font-family: Baufra Medium, sans-serif;
      text-align: center;
      text-decoration: none;
      margin: 0 10px 0 10px;
      font-size: 16px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      letter-spacing: normal;
      color: #4e8af3;
      display: flex;
      align-items: center; }
      .top-menu nav a:hover {
        color: rgba(78, 138, 243, 0.8); }
      .top-menu nav a.active {
        border-bottom: solid 2px #fff; }
      @media (max-width: 1024px) {
        .top-menu nav a {
          margin-right: 20px; } }

.mobile_menu {
  transform: scale(0);
  transform-origin: top;
  display: block;
  position: relative;
  height: 100%;
  width: 100%;
  padding: 0;
  z-index: 3;
  background-image: inherit; }

.dobbi-logo-w {
  display: none; }

.hero-image.active {
  position: fixed;
  top: 0;
  z-index: 5;
  height: 100%;
  overflow: auto; }
  .hero-image.active .switcher-wrapper {
    display: none; }
  .hero-image.active .hero-inner {
    box-shadow: none; }
  .hero-image.active .hero-img-wrapper {
    display: none; }
  @media (max-width: 768px) {
    .hero-image.active {
      padding-top: 50px;
      background: linear-gradient(180deg, #12dce7 0%, #72ee6d 100%) !important;
      box-shadow: 0 2px 4px 0 rgba(0, 35, 178, 0.08) !important; } }
  .hero-image.active .app-download-home {
    visibility: hidden;
    z-index: -1; }
  .hero-image.active .header-hero-img {
    display: none; }
  .hero-image.active .logo-container {
    background: none; }
  .hero-image.active .dobbi-logo {
    display: block !important; }
  .hero-image.active .mobile_menu {
    transform: scale(1);
    transition: 0.2s;
    background: rgba(0, 0, 0, 0); }
    @media (min-width: 769px) {
      .hero-image.active .mobile_menu {
        display: none; } }
    .hero-image.active .mobile_menu nav {
      padding-top: 30px;
      padding-bottom: 20px; }
      .hero-image.active .mobile_menu nav .order-button {
        max-width: 130px;
        margin: 0 auto;
        color: #42e5aa;
        border-radius: 30px;
        font-size: 12px;
        padding: 6px 16px;
        background-color: #ffffff; }
        @media (max-width: 768px) {
          .hero-image.active .mobile_menu nav .order-button.desktop {
            display: none; } }
        .hero-image.active .mobile_menu nav .order-button.mobile {
          display: none; }
          @media (max-width: 768px) {
            .hero-image.active .mobile_menu nav .order-button.mobile {
              display: block; } }
        .hero-image.active .mobile_menu nav .order-button:hover, .hero-image.active .mobile_menu nav .order-button.active {
          border-bottom: none; }
      .hero-image.active .mobile_menu nav a {
        display: block;
        padding: 1em;
        font-size: 18px;
        font-family: Baufra Semi, sans-serif;
        color: #fff;
        line-height: 22px;
        text-align: center;
        text-shadow: 0 2px 28px 0 rgba(16, 187, 82, 0.43); }
        @media (max-width: 425px) {
          .hero-image.active .mobile_menu nav a {
            font-size: 18px; } }
      @media (max-width: 425px) {
        .hero-image.active .mobile_menu nav {
          padding-bottom: 30px; } }
    .hero-image.active .mobile_menu .mobile_applink {
      font-size: 18px;
      font-family: Baufra Semi, sans-serif;
      color: #ffffff;
      text-align: center;
      text-shadow: 0 2px 28px 0 rgba(16, 187, 82, 0.43); }
      .hero-image.active .mobile_menu .mobile_applink > .app-links {
        justify-content: center; }
  .hero-image.active .hero-inner {
    background: none; }
  .hero-image.active .hamburger-button > i {
    background-color: #fff; }

.language-switcher {
  cursor: pointer;
  width: 110px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  padding: 10px 20px;
  border-radius: 10px;
  transition: all 0.2; }
  @media (max-width: 768px) {
    .language-switcher {
      margin: 0 auto;
      padding: 15px 20px 10px;
      margin-bottom: 60px; } }
  .language-switcher span {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%; }
  .language-switcher img {
    transition: transform 0.2s;
    transform: rotate(180deg); }
  .language-switcher .lang-container {
    line-height: 2;
    width: 110px;
    position: absolute;
    align-items: flex-start;
    flex-direction: column;
    margin-top: -10px;
    top: 100%;
    left: 0;
    background-color: #fff;
    padding: 10px 20px 10px;
    border-radius: 0 0 10px 10px;
    transform: scaleY(0);
    transform-origin: top;
    transition: all 0.2s;
    display: flex; }
    .language-switcher .lang-container a {
      color: #000;
      opacity: 0.3; }
      .language-switcher .lang-container a:hover {
        opacity: 0.4; }
  .language-switcher.open {
    border-radius: 10px 10px 0 0; }
    .language-switcher.open img {
      transform: rotate(0deg); }
    .language-switcher.open .lang-container {
      transform: scaleY(1); }

.app-download-home {
  display: flex; }

.lng-switcher {
  width: 100px;
  border-radius: 30px;
  background-color: rgba(255, 255, 255, 0.4);
  justify-content: space-between; }
  @media (max-width: 768px) {
    .lng-switcher {
      width: 120px; } }
  .lng-switcher .lang-item {
    cursor: pointer;
    border-radius: 30px;
    padding: 6px; }
    @media (max-width: 768px) {
      .lng-switcher .lang-item {
        width: 56px; } }
    .lng-switcher .lang-item.active {
      background: #fff; }
      .lng-switcher .lang-item.active span {
        color: #000; }
    .lng-switcher .lang-item img {
      height: 18px;
      width: 18px; }
    .lng-switcher .lang-item span {
      margin-left: 4px;
      font-family: Baufra Semi, sans-serif;
      font-size: 10px;
      color: #fff; }

.switcher-wrapper {
  max-height: 100%; }
  @media (max-width: 768px) {
    .switcher-wrapper {
      padding: 18px 25px;
      justify-content: space-between;
      max-height: 50px;
      background-color: #ffffff; } }

.switcher-header {
  display: none;
  position: relative;
  z-index: 1;
  border-bottom: 1px solid #e5ecf3; }
  .switcher-header .lng-switcher {
    border: 1px solid #e5ecf3;
    border-radius: 30px;
    background-color: #f1f5f8;
    box-shadow: inset 0 4px 25px 0 rgba(83, 170, 112, 0.2); }
  .switcher-header .lang-item span {
    color: #000; }
  .switcher-header .language-span {
    font-family: Baufra Semi, sans-serif;
    opacity: 0.2;
    color: #000000; }
  @media (max-width: 768px) {
    .switcher-header {
      display: flex; } }

.switcher-menu {
  display: block; }
  @media (max-width: 768px) {
    .switcher-menu {
      display: none; } }

.home-page-wrapper .hero-image {
  min-height: 544px !important; }
  .home-page-wrapper .hero-image.active {
    min-height: 100% !important; }

@media (max-width: 768px) {
  .home-page-wrapper .hero-img-wrapper {
    display: block; } }

.home-page-wrapper .hero-img-wrapper .header-text {
  display: flex; }
  .home-page-wrapper .hero-img-wrapper .header-text .header-text-phone {
    display: block; }
